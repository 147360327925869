import React from "react";
import { Route, Switch } from "react-router-dom";
import "./index.css";
import UserList from "../../User/List";
import UserData from "../../User/CreateEdit";
import Broiler from "../../Broiler";
import Fish from "../../Fish";
import Lay from "../../Lay";
import Pullet from "../../Pullet";
import Home from "../../Home";

const Content = () => {
  return (
    <Switch>    
      <Route path="/administracao/usuarios/:page/:limit/:sort/:query">
        <UserList />
      </Route>
      <Route path="/administracao/usuario/:id">
        <UserData />
      </Route>
      <Route path="/administracao/usuario">
        <UserData />
      </Route>
      <Route path="/previsoes-frango-de-corte">
        <Broiler />
      </Route>
      <Route path="/previsoes-tilapias">
        <Fish />
      </Route>
      <Route path="/previsoes-postura">
        <Lay />
      </Route>
      <Route path="/previsoes-franga">
        <Pullet />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default Content;

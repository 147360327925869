import React, { useState, useEffect } from "react";
import Loading from "../../../../components/Loading";
import api from "../../../../services/api";
import Swal from "sweetalert2";

const CreateEditIngredient = props => {
  const initialIngredientState = {
    name: "",
    user: "",
    pd: "",
    ed: "",
    pDis: "",
    ca: "",
    naDis: "",
    ciDis: "",
    kDis: "",
    arg: "",
    his: "",
    ile: "",
    leu: "",
    lys: "",
    met: "",
    metCys: "",
    phe: "",
    pheThr: "",
    thr: "",
    trp: "",
    val: "",
    price: ""
  };

  const [ingredient, setIngredient] = useState(initialIngredientState);

  const [loading, setLoading] = useState(false);

  const [ingredientBase, setIngredientBase] = useState("");

  const inputHandler = (value, key) => {
    const copyIngredient = { ...ingredient };
    copyIngredient[key] = value;
    setIngredient(copyIngredient);
  };

  const selectHandler = index => {
    setIngredientBase(index);
    const { _id, ...ingredientCopy } = props.ingredients[index];
    setIngredient(index === "" ? initialIngredientState : ingredientCopy);
  };

  const saveIngredient = async e => {
    e.preventDefault();
    const response = await Swal.fire({
      title: `${props.id ? "Criar" : "Editar"} Ingrediente`,
      text: `Deseja confirmar ${
        props.id ? "criação" : "edição"
      } do ingrediente`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: `Sim, ${props.id ? "Criar" : "Editar"} Ingrediente!`,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        if (props.id) {
          return await api.put("fishingredient/" + props.id, ingredient);
        } else {
          return await api.post("fishingredient", ingredient);
        }
      }
    });
    if (response.value) {
      Swal.fire("Criar Ingrediente", response.value.data.message, "success");
      setIngredient(initialIngredientState);
      props.setId("");
      props.setIngredients(true);
    }
  };

  const removeIngredient = async ()=>{
    const response = await Swal.fire({
      title: 'Remover Ingrediente',
      text: 'Deseja remover o ingrediente',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: 'Sim, remover o ingrediente',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await api.delete("fishingredient/" + props.id, ingredient);
      }
    });
    if (response.value) {
      Swal.fire("Remover Ingrediente", response.value.data.message, "success");
      setIngredient(initialIngredientState);
      props.setId("");
      props.setIngredients(true);
      props.setTab(3);
    }
  }

  useEffect(() => {
    const loadIngredient = async () => {
      setIngredient(initialIngredientState);
      if (props.id) {
        const response = await api.get(`fishingredient/${props.id}`);
        setIngredient(response.data);
      }
      setLoading(false);
    };
    if (props.hasGet) {
      setLoading(true);
      props.setGet(false);
      loadIngredient();
    }
  }, [props, initialIngredientState]);

  return loading ? (
    <Loading type="spin" color="#0451e8" height={30} width={30} />
  ) : (
    <div className="wrapper wrapper-content animated fadeInRight">
      <div className="row">
        <div className="col-md-12 text-left">
          <div className="ibox float-e-margins">
            <div className="ibox-title opcoes">Dados do Ingrediente</div>
            <div className="ibox-content icons-box">
              <form onSubmit={saveIngredient}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nome</label>
                      <input
                        type="text"
                        placeholder="Nome"
                        onChange={e => inputHandler(e.target.value, "name")}
                        value={ingredient.name}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Preço (R$)</label>
                      <input
                        type="number"
                        placeholder="Preço (R$)"
                        onChange={e => inputHandler(e.target.value, "price")}
                        value={ingredient.price}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Ingrediente Base</label>
                      <select
                        onChange={e => selectHandler(e.target.value)}
                        value={ingredientBase}
                        className="form-control"
                      >
                        <option value="">Selecione um ingrediente Base</option>
                        {props.ingredients.map((item, index) => (
                          <option value={index} key={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>PD</label>
                      <input
                        type="number"
                        placeholder="PD"
                        onChange={e => inputHandler(e.target.value, "pd")}
                        value={ingredient.pd}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>ED</label>
                      <input
                        type="number"
                        placeholder="ED"
                        onChange={e => inputHandler(e.target.value, "ed")}
                        value={ingredient.ed}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>P dis</label>
                      <input
                        type="number"
                        placeholder="P dis"
                        onChange={e => inputHandler(e.target.value, "pDis")}
                        value={ingredient.pDis}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Ca</label>
                      <input
                        type="number"
                        placeholder="Ca"
                        onChange={e => inputHandler(e.target.value, "ca")}
                        value={ingredient.ca}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Na dis</label>
                      <input
                        type="number"
                        placeholder="Na dis"
                        onChange={e => inputHandler(e.target.value, "naDis")}
                        value={ingredient.naDis}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Cl dis</label>
                      <input
                        type="number"
                        placeholder="Cl dis"
                        onChange={e => inputHandler(e.target.value, "ciDis")}
                        value={ingredient.ciDis}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>K dis</label>
                      <input
                        type="number"
                        placeholder="K dis"
                        onChange={e => inputHandler(e.target.value, "kDis")}
                        value={ingredient.kDis}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Arg</label>
                      <input
                        type="number"
                        placeholder="Arg"
                        onChange={e => inputHandler(e.target.value, "arg")}
                        value={ingredient.arg}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>His</label>
                      <input
                        type="number"
                        placeholder="His"
                        onChange={e => inputHandler(e.target.value, "his")}
                        value={ingredient.his}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Ile</label>
                      <input
                        type="number"
                        placeholder="Ile"
                        onChange={e => inputHandler(e.target.value, "ile")}
                        value={ingredient.ile}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Leu</label>
                      <input
                        type="number"
                        placeholder="Leu"
                        onChange={e => inputHandler(e.target.value, "leu")}
                        value={ingredient.leu}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Lys</label>
                      <input
                        type="number"
                        placeholder="Lys"
                        onChange={e => inputHandler(e.target.value, "lys")}
                        value={ingredient.lys}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Met</label>
                      <input
                        type="number"
                        placeholder="Met"
                        onChange={e => inputHandler(e.target.value, "met")}
                        value={ingredient.met}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Met+cys</label>
                      <input
                        type="number"
                        placeholder="Met+cys"
                        onChange={e => inputHandler(e.target.value, "metCys")}
                        value={ingredient.metCys}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Phe</label>
                      <input
                        type="number"
                        placeholder="Phe"
                        onChange={e => inputHandler(e.target.value, "phe")}
                        value={ingredient.phe}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Phe+Thr</label>
                      <input
                        type="number"
                        placeholder="Phe+Thr"
                        onChange={e => inputHandler(e.target.value, "pheThr")}
                        value={ingredient.pheThr}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Thr</label>
                      <input
                        type="number"
                        placeholder="Thr"
                        onChange={e => inputHandler(e.target.value, "thr")}
                        value={ingredient.thr}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Trp</label>
                      <input
                        type="number"
                        placeholder="Trp"
                        onChange={e => inputHandler(e.target.value, "trp")}
                        value={ingredient.trp}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Val</label>
                      <input
                        type="number"
                        placeholder="Val"
                        onChange={e => inputHandler(e.target.value, "val")}
                        value={ingredient.val}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="hr-line-dashed"></div>
                    {ingredient._id && !ingredient.user ? null : (
                      <button className="btn btn-primary m-r-sm">
                        {props.id ? "Editar" : "Criar"} Ingrediente{" "}
                        <i className="fa fa-edit" />
                      </button>
                    )}
                    {ingredient._id && ingredient.user ? (
                      <button
                        className="btn btn-danger m-r-sm"
                        type="button"
                        onClick={removeIngredient}
                      >
                        Remover Ingrediente <i className="fa fa-trash" />
                      </button>
                    ) : null}
                    <button
                      className="btn btn-warning m-l-sm"
                      type="button"
                      onClick={() => {
                        setIngredient(initialIngredientState);
                        props.setId("");
                      }}
                    >
                      Cancelar <i className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEditIngredient;

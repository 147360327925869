import React from "react";
import utils from "../../../utils/functions";

const Results = (props) => {
  return (
    <div className="resultados mt-3 ">
      <table className="table ">
        <thead className="thead-dark ">
          <tr>
            <th className="col">Fase</th>
            <th className="col">Lys</th>
            <th className="col">Met+Cys</th>
            <th className="col">Thr</th>
            <th className="col">Trp</th>
            <th className="col">Arg</th>
            <th className="col">Val</th>
            <th className="col">Ile</th>
            <th className="col">Leu</th>
            <th className="col">His</th>
            <th className="col">Phe+Tyr</th>
            <th className="col">Gly+Ser</th>
            <th className="col">EM</th>
          </tr>
          <tr>
            <th className="col">Semanas</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">Kcal/kg Ração</th>
          </tr>
        </thead>
        <tbody>
          {props.result.map((item, index) => (
            <tr key={index}>
              <td>
                {item.start} a {item.end}
              </td>
              <td>{utils.filterMask("number", item.lysAverage, 3)}</td>
              <td>{utils.filterMask("number", item.metCysAverage, 3)}</td>
              <td>{utils.filterMask("number", item.thrAverage, 3)}</td>
              <td>{utils.filterMask("number", item.trpAverage, 3)}</td>
              <td>{utils.filterMask("number", item.argAverage, 3)}</td>
              <td>{utils.filterMask("number", item.valAverage, 3)}</td>
              <td>{utils.filterMask("number", item.ileAverage, 3)}</td>
              <td>{utils.filterMask("number", item.leuAverage, 3)}</td>
              <td>{utils.filterMask("number", item.hisAverage, 3)}</td>
              <td>{utils.filterMask("number", item.pheTyrAverage, 3)}</td>
              <td>{utils.filterMask("number", item.glySerAverage, 3)}</td>
              <td>{utils.filterMask("number", item.emResult, 0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Results;

import React from "react";

const FormInputs = (props) => {
  const hasError = (key, value) =>
    key === "temperature" && (+value < 21 || +value > 31);

  return (
    <form
      className="form-inputs-postura"
      onSubmit={(event) => {
        event.preventDefault();
        props.generateReport();
      }}
    >
      <table className="table">
        <thead className="thead-dark form-inputs-postura__header">
          <tr>
            <th
              className="col form-inputs-postura__header--fixed text-center"
              colSpan="3"
            >
              Entrada de dados
            </th>
          </tr>
          <tr>
            <th className="col form-inputs-postura__header--fixed">
              Peso Inicial (g)
            </th>
            <th className="col form-inputs-postura__header--fixed">
              Peso Final (g)
            </th>
            <th className="col form-inputs-postura__header--fixed">
              Temperatura (ºC)
            </th>
          </tr>
        </thead>
        <tbody>
          {props.inputs.feedProgram.map((input, indexRow) => (
            <tr key={`row${indexRow}`}>
              {Object.keys(input).map((key, indexColumn) => (
                <td
                  key={`column${indexColumn}`}
                  className={hasError(key, input[key].value) ? "has-error" : ""}
                >
                  <input
                    type="number"
                    className="form-control"
                    placeholder={input[key].label}
                    value={props.inputs.feedProgram[indexRow][key].value}
                    disabled={key === "start" && indexRow > 0}
                    onChange={(event) =>
                      props.inputHandler(indexRow, key, event, "feedProgram")
                    }
                  />
                  {hasError(key, input[key].value) ? (
                    <small style={{ color: "#ed5565" }}>
                      Não recomenda-se alimentar
                    </small>
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="8">
              <div className="form-inputs-postura__footer d-flex justify-content-end">
                {props.inputs.feedProgram.length > 1 ? (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm mr-1"
                    onClick={() => props.removeLine("feedProgram")}
                  >
                    Remover linha
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={props.addLineFeedProgram}
                >
                  Adicionar Linha
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="row">
        <div className="col-md-12 text-center">
          <button type="submit" className="btn btn-primary btn-sm ">
            Gerar Relatório
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormInputs;

import React, { Fragment } from "react";
import HeaderContent from "../../components/HeaderContent";
import { withRouter } from "react-router-dom";
//import homeBottom from "../../assets/img/home_bottom.jpeg";

const Home = () => {
  return (
    <Fragment>
      <HeaderContent
        label="Portal da Modelagem da Avicultura"
        small="Poultry Model Web Portal"
        links={[{ label: "Home", active: true }]}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-md-12 text-left">
            <div className="ibox float-e-margins">
              <div className="ibox-title opcoes">
                <h2>
                  <b>POULTRY MODEL </b>
                </h2>
              </div>
              <div className="ibox-content icons-box">
                <div className="row equal">
                  <div
                    className="col-md-12 text-justify img-home"
                    style={{ fontSize: "1.7rem" }}
                  >
                    <div>
                      <h3>
                        Ferramentas para elaboração de programa nutricional para
                        aves e peixes
                      </h3>
                      <p>
                        As ferramentas práticas podem gerar programas
                        nutricionais de acordo com a categoria animal
                        selecionada. Os programas nutricionais são calculados
                        com base no potencial genético de crescimento, que geram
                        uma demanda por aminoácidos essenciais e energia
                        metabolizável.
                      </p>
                      <p>
                        Também foi desenvolvida uma ferramenta para elaboração
                        de programa nutricional para Tilápias, no qual os
                        usuários poderão elaborar as rações de acordo com o
                        objetivo de crescimento.
                      </p>

                      <h3>Modelos de Simulação</h3>
                      <p>
                        Os softwares Broiler Grwoth Model e o Egg Production
                        Model são modelos de simulação construídos a partir de
                        uma abordagem mecanicista, permitindo aos usuários
                        simular as repostas de frangos de corte e aves de
                        postura de acordo com os seguintes cenários de criação:
                        ambiente, dieta, genética e aspectos econômicos. Os
                        resultados produzidos modelo inclui a predição do
                        desempenho, composição corporal e a exigência de
                        aminoácidos essenciais e energia. Além disso, o Broiler
                        Growth Model também calcula o rendimento de carcaça e
                        cortes de aves em crescimento. Uma ferramenta de
                        formulação de ração foi incluída aos modelos
                        mecanicistas, permitindo a formulação e simulação das
                        rações e a otimização de programas nutricionais,
                        maximizando o retorno econômico da atividade.
                      </p>
                      <p>Para maiores informações, entre em contato conosco:</p>
                      <ul>
                        <li>
                          Nilva Sakomura – <b>nilvasakomura@gmail.com</b>
                        </li>
                        <li>
                          Matheus Reis – <b>matheusdpreis@gmail.com</b>
                        </li>
                      </ul>
                      <p>
                        Esses modelos foram desenvolvidos por Nilva Sakomura e
                        Matheus Reis no laboratório de ciências avícolas da
                        faculdade de ciências agrárias e veterinárias da
                        Universidade Estadual Paulista “Julio de Pesquita
                        Filho”, localizado em Jaboticabal-SP. Os modelos de
                        simulação estão registrados junto ao Instituto Nacional
                        Da Propriedade Intelectual Processo No: BR512019002861-9
                        (BGM) e Processo No: BR512019001104-0 (EPM).
                      </p>
                    </div>
                    <div>
                      <div className="hr-line-dashed"></div>
                    </div>
                    <div>
                      <h3>
                        Tools to elaborate feeding program for poultry and fish
                      </h3>
                      <p>
                        Practical tools can generate nutritional programs
                        according to animal category. Nutritional programs are
                        calculated based on genetic potential for growth, which
                        generates a need for essential amino acids and
                        metabolizable energy.
                      </p>
                      <p>
                        Our team also developed, a tool to elaborate nutritional
                        programs for Tilapia. In this practical tool,
                        nutritionists can produce feed formulas based on the
                        objective of Tilapia’s growth.
                      </p>

                      <h3>Simulation models</h3>
                      <p>
                        The Broiler Growth Model and the Egg Production Model
                        are simulation models based on mechanistic approach,
                        allowing users to simulate the response of broilers and
                        laying hens according to distinct scenarios, such as:
                        environment, diets, genetic, and economic aspects. The
                        output consist on performance, including feed intake,
                        body composition, nutrient and energy requirement.
                        Specifically, the Broiler Growth Model calculates parts
                        yield. A feed formulator tool is included in both
                        models, enabling to formulate and simulate specific
                        feeds and optimize feed programs that maximize economic
                        return.
                      </p>
                      <p>
                        For information, please contact one of the email address
                        below:
                      </p>
                      <ul>
                        <li>
                          Nilva Sakomura – <b>nilvasakomura@gmail.com</b>
                        </li>
                        <li>
                          Matheus Reis – <b>matheusdpreis@gmail.com</b>
                        </li>
                      </ul>
                      <p>
                        These models were developed by prof. Nilva Sakomura and
                        Matheus REis collaborators at UNESP Jaboticabal-SP, and
                        are registered at National Institute of Industrial
                        Propriety (Process No: BR512019002861-9 (BGM) and
                        Process No: BR512019001104-0 (EPM))
                      </p>
                    </div>

                    {/*  <div>
                      <img
                        className="img-responsive"
                        src={homeBottom}
                        alt="home bottom"
                      ></img>
                    </div> */}
                  </div>
                  {/* <div className="col-md-6 text-justify img-home"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Home);

import React from "react";
import utils from "../../../utils/functions";

const Results = (props) => {
  return (
    <div className="resultados mt-3 ">
      <table className="table ">
        <thead className="thead-dark ">
          <tr>
            <th className="col">Fase (g/peixe)</th>
            <th className="col">Consumo (g/peixe/dia)</th>
            <th className="col">Consumo (%)</th>
            <th className="col">Lis (%)</th>
            <th className="col">Met+Cis (%)</th>
            <th className="col">Tre (%)</th>
            <th className="col">Trp (%)</th>
            <th className="col">Val (%)</th>
            <th className="col">Arg (%)</th>
            <th className="col">Ile (%)</th>
            <th className="col">Leu (%)</th>
            <th className="col">Fen (%)</th>
            <th className="col">His (%)</th>
            <th className="col">ED (kcal/peixe/dia)</th>
          </tr>
          <tr>
            <th className="col">g/peixe</th>
            <th className="col">g/peixe/dia</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">kcal/peixe/dia</th>
          </tr>
        </thead>
        <tbody>
          {props.result.map((item, index) => (
            <tr key={index}>
              <td>
                {item.start} a {item.end}
              </td>
              <td>{utils.filterMask("number", item.dfi, 2)}</td>
              <td>{utils.filterMask("number", item.fi, 2)}</td>
              <td>{utils.filterMask("number", item.lysP, 2)}</td>
              <td>{utils.filterMask("number", item.metCysP, 2)}</td>
              <td>{utils.filterMask("number", item.thrP, 2)}</td>
              <td>{utils.filterMask("number", item.trpP, 2)}</td>
              <td>{utils.filterMask("number", item.valP, 2)}</td>
              <td>{utils.filterMask("number", item.argP, 2)}</td>
              <td>{utils.filterMask("number", item.ileP, 2)}</td>
              <td>{utils.filterMask("number", item.leuP, 2)}</td>
              <td>{utils.filterMask("number", item.pheP, 2)}</td>
              <td>{utils.filterMask("number", item.hisP, 2)}</td>
              <td>{utils.filterMask("number", item.de, 2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Results;

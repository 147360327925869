import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Application from "./views/Application";
import Login from "./views/Login";
import Forgot from "./views/Forgot";
import ChangePass from "./views/ChangePass";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/animate.css";
import "./assets/font-awesome/css/font-awesome.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path="/new-password/:token"
          render={(props) => <ChangePass {...props} />}
        />
        <Route path="/forgot">
          <Forgot />
        </Route>
        <Route path="/login" render={(props) => <Login {...props} />} />
        <Route path="/" render={(props) => <Application {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;

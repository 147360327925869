import React from "react";
import utils from "../../../../utils/functions";

const Results = props => {
  return props.tableIngredients.map(dieta => (
    <div className="resultados mt-3 " key={dieta.fase}>
      <table className="table table-resultados">
        <thead className="thead-dark table-resultados__header">
          <tr>
            <th className="col" colSpan="3">
              {dieta.fase}
            </th>
          </tr>
          <tr>
            <th className="col">Ingrediente</th>
            <th className="col">Inclusão</th>
            <th className="col">Preço/kg</th>
          </tr>
        </thead>
        <tbody>
          {dieta.ingredients
            .sort((a, b) => {
              if (a.percentage > b.percentage) {
                return -1;
              }
              if (a.percentage < b.percentage) {
                return 1;
              }
              return 0;
            })
            .map(ingredient => (
              <tr key={ingredient.name}>
                <td>{ingredient.name}</td>
                <td>{utils.filterMask("number", ingredient.percentage, 2)}%</td>
                <td>{utils.filterMask("number", ingredient.price, 2)} </td>
              </tr>
            ))}
          <tr>
            <td></td>
            <td>
              {dieta.ingredients[1]
                ? utils.filterMask(
                    "number",
                    dieta.ingredients
                      .map(ingredient => ingredient.percentage)
                      .reduce((p, n) => {
                        return p + n;
                      }),
                    2
                  )
                : utils.filterMask(
                    "number",
                    dieta.ingredients[0].percentage,
                    2
                  )}
              %
            </td>
            <td>
              {dieta.ingredients[1]
                ? utils.filterMask(
                    "number",
                    dieta.ingredients
                      .map(ingredient => ingredient.price)
                      .reduce((p, n) => p + n),
                    2
                  )
                : utils.filterMask("number", dieta.ingredients[0].price, 2)}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table table-resultados">
        <thead className="thead-dark table-resultados__header">
          <tr>
            <th className="col" colSpan="2">
              &nbsp;
            </th>
          </tr>
          <tr>
            <th className="col">Nutriente</th>
            <th className="col">Composição</th>
          </tr>
        </thead>
        <tbody>
          {dieta.nutrients.sort((a, b) => {
              if (a.value > b.value) {
                return -1;
              }
              if (a.value < b.value) {
                return 1;
              }
              return 0;
            }).map(nutrient => (
            <tr key={nutrient.name}>
              <td>{nutrient.name}</td>
              <td>{utils.filterMask("number", nutrient.value, 2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ));
};

export default Results;

import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import logo from "../../assets/img/lavinesp.png";
import api from "../../services/api";
import "./index.css";

const Forgot = (props) => {
  const year = new Date().getFullYear();

  const erroMessageInitialState = {
    message: "",
    username: false,
  };

  const [form, setForm] = useState({
    username: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(erroMessageInitialState);

  const submitData = useCallback(
    async (e) => {
      e.preventDefault();
      if (!form.username) {
        return setErrorMessage({
          message: "Digite um email valido!",
          username: !form.username,
        });
      }
      setLoading(true);
      const response = await api.post("/auth/forgot", form).catch((err) => err);
      setLoading(false);
      if (!response.data) {
        return Swal.fire("Esqueci minha senha", response.message, "error");
      }
      Swal.fire(
        "Esqueci minha senha",
        "Email de recuparação de senha enviado com sucesso!",
        "success"
      );
      setForm({
        username: "",
      });
    },
    [form]
  );

  const inputFormHandler = (value, key) => {
    const copyForm = { ...form };
    copyForm[key] = value;
    setForm(copyForm);
  };

  return (
    <div className="forgot">
      <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
          <div className="forgot__logo">
            <img className="img-responsive" src={logo} alt="logo" />
          </div>
          <h3>Digite seu email para recuperar sua senha</h3>
          <form className="m-t" onSubmit={submitData}>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                required=""
                value={form.username}
                disabled={loading}
                onChange={(e) => inputFormHandler(e.target.value, "username")}
              />
            </div>
            {errorMessage.username && (
              <div className="alert alert-danger">{errorMessage.message}</div>
            )}
            <button
              type="submit"
              className="btn btn-primary block full-width m-b"
              disabled={loading}
            >
              {loading ? "Aguarde..." : "Enviar"}
            </button>

            <a href="/">
              <small>Voltar</small>
            </a>
          </form>
          <p className="m-t">
            {" "}
            <small>Desenvolvido por Rafael Caseiro &copy; {year}</small>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Forgot;

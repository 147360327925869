import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";

const Nutrients = props => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-6 formulador__search">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Digite o nome do nutriente"
            onChange={event =>
              props.inputSearchNutrientsHandler(event.target.value)
            }
            value={props.inputSearchNutrients}
          />
          {props.inputSearchNutrients ? (
            <i
              className="fa fa-trash pointer"
              onClick={props.clearNutrientsSearch}
            />
          ) : (
            <i className="fa fa-search" />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <small>Lista de nutrientes</small>
          <div className="formulador__item">
            {props.nutrientsSearch.map((nutrient, index) => (
              <div
                className="formulador__item-left pointer"
                key={`nutrientLeft${index}`}
                onClick={() => props.selectNutrient(nutrient.name)}
              >
                {nutrient.name}
                <i className="fa fa-arrow-right"></i>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <small>Nutrientes Selecionados</small>
          <div className="formulador__item">
            {props.nutrientsSides[1].map((nutrient, index) => (
              <div
                className="formulador__item-right"
                key={`nutrientLeft${index}`}
              >
                <span
                  className="pointer"
                  onClick={e => props.removeNutrient(index, e)}
                >
                  {nutrient.name}
                </span>

                <div>
                  <TextField
                    type="number"
                    className="form-control form-control-sm"
                    label="Min"
                    value={props.nutrientsSides[1][index].min}
                    onChange={event =>
                      props.inputSelectedNutrientItemHandler(
                        event.target.value,
                        "min",
                        index
                      )
                    }
                    variant="outlined"
                  />
                  <TextField
                    type="number"
                    className="form-control form-control-sm"
                    label="Máx"
                    value={props.nutrientsSides[1][index].max}
                    onChange={event =>
                      props.inputSelectedNutrientItemHandler(
                        event.target.value,
                        "max",
                        index
                      )
                    }
                    variant="outlined"
                  />
                </div>
                <i className="fa fa-arrow-left"></i>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Nutrients;

import React, { Fragment, useState, useEffect } from "react";
import HeaderContent from "../../../components/HeaderContent";
import Loading from "../../../components/Loading";
import Checkbox from "../../../components/Checkbox";
import api from "../../../services/api";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

const CreateEditUser = props => {
  const [user, setUser] = useState({
    name: "",
    responsibility: "",
    username: "",
    status: true,
    roles: [],
    permissions: []
  });

  const [roles, setRoles] = useState([]);

  const [permissions, setPermissions] = useState([]);

  const [runLoadUser, setRunLoadUser] = useState(true);

  const [loading, setLoading] = useState(true);

  const inputHandler = (value, key) => {
    const copyUser = { ...user };
    copyUser[key] = value;
    setUser(copyUser);
  };

  const rolesHandler = (checked, index) => {
    const copyRoles = [...roles];
    copyRoles[index].checked = checked;
    setRoles(copyRoles);
  };

  const permissionsHandler = (checked, index) => {
    let copyPermissions = [...permissions];
    copyPermissions[index].checked = checked;
    copyPermissions = copyPermissions.map(permission => {
      if (permission.parent === copyPermissions[index].value) {
        permission.checked = checked;
      }
      return permission;
    });
    setPermissions(copyPermissions);
  };

  const saveUser = async e => {
    e.preventDefault();
    const requestUser = { ...user };
      requestUser.roles = [];
      requestUser.permissions = [];
      roles.forEach(role => {
        if (role.checked) {
          requestUser.roles.push(role.value);
        }
      });
      permissions.forEach(permission => {
        if (permission.checked) {
          requestUser.permissions.push(permission.value);
        }
      });
    if (!props.match.params.id) {      
      const response = await Swal.fire({
        title: "Criar Usuário",
        text: "Deseja confirmar criação do usuário",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Criar Usuários!",
        showLoaderOnConfirm: true,
        preConfirm: async () => await api.post("user", requestUser)
      });
      if (response.value) {
        Swal.fire("Criar Usuário", response.value.data.message, "success");
        props.history.replace(props.match.path + "/" + response.value.data.id);
        setRunLoadUser(true);
      }
    }else{
      const response = await Swal.fire({
        title: "Editar Usuário",
        text: "Deseja confirmar edição do usuário",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar Usuários!",
        showLoaderOnConfirm: true,
        preConfirm: async () => await api.put("user/" + props.match.params.id, requestUser)
      });
      if (response.value) {
        Swal.fire("Editar Usuário", response.value.data.message, "success");        
        setRunLoadUser(true);
      }
    }
  };
  useEffect(() => {
    const loadUser = async () => {
      let response = { data: { ...user } };
      if (props.match.params.id) {
        response = await api.get(`user/${props.match.params.id}`);
        setUser(response.data);
      }
      const responseRoles = await api.get("roles");
      setRoles(
        responseRoles.data.map(role => ({
          ...role,
          checked: response.data.roles.indexOf(role.value) > -1
        }))
      );
      const responsePermissions = await api.get("permissions");
      setPermissions(
        responsePermissions.data.map(permission => ({
          ...permission,
          checked: response.data.permissions.indexOf(permission.value) > -1
        }))
      );
      setLoading(false);
    };
    if (runLoadUser) {
      setRunLoadUser(false);
      loadUser();
    }
  }, [props.match, runLoadUser, user]);

  return loading ? (
    <Loading type="spin" color="#0451e8" height={30} width={30} />
  ) : (
    <Fragment>
      <HeaderContent
        label={user.name}
        previewPage={props.history.goBack}
        links={[
          { label: "home", link: "/home" },
          { label: "Adminstração" },
          {
            label: "Usuários",
            link: "/administracao/usuarios/1/30/-created/%7B%7D"
          },
          { label: "Usuário", active: true }
        ]}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-md-12 text-left">
            <div className="ibox float-e-margins">
              <div className="ibox-title opcoes">Dados do Usuário</div>
              <div className="ibox-content icons-box">
                <form onSubmit={saveUser}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Nome</label>
                        <input
                          type="text"
                          placeholder="Nome"
                          onChange={e => inputHandler(e.target.value, "name")}
                          value={user.name}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>E-mail</label>
                        <input
                          type="text"
                          placeholder="E-mail"
                          disabled={!!props.match.params.id}
                          onChange={e =>
                            inputHandler(e.target.value, "username")
                          }
                          value={user.username}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Cargo</label>
                        <input
                          type="text"
                          placeholder="Cargo"
                          onChange={e =>
                            inputHandler(e.target.value, "responsibility")
                          }
                          value={user.responsibility}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Status</label>
                        <select
                          placeholder="E-mail"
                          onChange={e => inputHandler(e.target.value, "status")}
                          value={user.status}
                          className="form-control"
                        >
                          <option value={true}>Ativo</option>
                          <option value={false}>Inativo</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Roles</label>
                        {roles.map((item, index) => (
                          <div key={item.value}>
                            <Checkbox
                              label={item.label}
                              checked={item.checked}
                              onChange={checked => rolesHandler(checked, index)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Permissões</label>
                        {permissions.map((item, index) => (
                          <div key={item.value}>
                            <Checkbox
                              label={item.label}
                              checked={item.checked}
                              onChange={checked =>
                                permissionsHandler(checked, index)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <div className="hr-line-dashed"></div>
                      <button className="btn btn-primary">
                        {props.match.params.id ? "Editar" : "Criar"} Usuário{" "}
                        <i className="fa fa-edit" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(CreateEditUser);

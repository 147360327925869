import React, { Fragment } from "react";

const Ingredients = props => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-6 formulador__search">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Digite o nome do ingrediente"
            onChange={event =>
              props.inputSearchIngredientsHandler(event.target.value)
            }
            value={props.inputSearchIngredients}
          />
          {props.inputSearchIngredients ? (
            <i
              className="fa fa-trash pointer"
              onClick={props.clearIngredientsSearch}
            />
          ) : (
            <i className="fa fa-search" />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <small>Lista de ingredientes</small>
          <div className="formulador__item">
            {props.ingredientsSearch.map((ingredient, index) => (
              <div
                className="formulador__item-left pointer"
                key={`ingredientLeft${index}`}
                onClick={() => props.selectIngredient(ingredient.nome)}
              >
                {ingredient.nome}
                <i className="fa fa-arrow-right"></i>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <small>Ingredientes Selecionados</small>
          <div className="formulador__item">
            {props.ingredientsSides[1].map((ingredient, index) => (
              <div
                className="formulador__item-right pointer"
                key={`ingredientLeft${index}`}
                onClick={e => props.removeIngredient(index, e)}
              >
                {ingredient.nome}
                <div>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="Preço"
                    value={props.ingredientsSides[1][index].userPrice}
                    onChange={event =>
                      props.inputSelectedIngredientItemHandler(
                        event.target.value,
                        "userPrice",
                        index
                      )
                    }
                  ></input>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="Min(%)"
                    value={props.ingredientsSides[1][index].min}
                    onChange={event =>
                      props.inputSelectedIngredientItemHandler(
                        event.target.value,
                        "min",
                        index
                      )
                    }
                  ></input>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="Máx(%)"
                    value={props.ingredientsSides[1][index].max}
                    onChange={event =>
                      props.inputSelectedIngredientItemHandler(
                        event.target.value,
                        "max",
                        index
                      )
                    }
                  ></input>
                </div>
                <i className="fa fa-arrow-left"></i>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Ingredients;

import React, { Fragment, useState } from "react";
import "./index.css";
import FormInputs from "./FormInputs";
import Formulador from "./Formulador";
import Result from "./Result";
import f from "../../utils/functions";
import HeaderContent from "../../components/HeaderContent";

const Lay = () => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const initialInput = {
    feedProgram: {
      start: {
        value: "",
        label: "Início"
      },
      end: {
        value: "",
        label: "Fim"
      },
      feedIntake: {
        value: "",
        label: "Consumo"
      },
      temperature: {
        value: "",
        label: "Temperatura"
      },
      bodyWeight: {
        value: "",
        label: "Peso"
      },
      lay: {
        value: "",
        label: "Postura"
      },
      eggOutput: {
        value: "",
        label: "Massa Ovo"
      }
    },
    small: "",
    medium: "",
    large: ""
  };

  const [inputs, setInputs] = useState({
    feedProgram: [
      {
        start: {
          value: 18,
          label: "Início"
        },
        end: {
          value: 22,
          label: "Fim"
        },
        feedIntake: {
          value: 63,
          label: "Consumo"
        },
        temperature: { value: 18 },
        bodyWeight: { value: 1.26 },
        lay: { value: 63.8 },
        eggOutput: { value: 35 }
      },
      {
        start: {
          value: 23,
          label: "Início"
        },
        end: {
          value: 30,
          label: "Fim"
        },
        feedIntake: {
          value: 90,
          label: "Consumo"
        },
        temperature: { value: 18 },
        bodyWeight: { value: 1.47 },
        lay: { value: 98 },
        eggOutput: { value: 53.016 }
      },
      {
        start: {
          value: 31,
          label: "Início"
        },
        end: {
          value: 40,
          label: "Fim"
        },
        feedIntake: {
          value: 95,
          label: "Consumo"
        },
        temperature: { value: 18 },
        bodyWeight: { value: 1.51 },
        lay: { value: 98 },
        eggOutput: { value: 56.544 }
      },
      {
        start: {
          value: 41,
          label: "Início"
        },
        end: {
          value: 50,
          label: "Fim"
        },
        feedIntake: {
          value: 95,
          label: "Consumo"
        },
        temperature: { value: 18 },
        bodyWeight: { value: 1.53 },
        lay: { value: 98 },
        eggOutput: { value: 56.16 }
      },
      {
        start: {
          value: 51,
          label: "Início"
        },
        end: {
          value: 60,
          label: "Fim"
        },
        feedIntake: {
          value: 95,
          label: "Consumo"
        },
        temperature: { value: 18 },
        bodyWeight: { value: 1.54 },
        lay: { value: 95 },
        eggOutput: { value: 54.897 }
      },      
    ],
    metodology: "average",
    populationRequirement: 80,
    small: "",
    medium: "",
    large: ""
  });

  const [lys, setLys] = useState(2);

  const [result, setResult] = useState([]);

  const addLineFeedProgram = () => {
    const copyInputs = { ...inputs };
    copyInputs.feedProgram.push({ ...initialInput.feedProgram });
    if (copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value) {
      copyInputs.feedProgram[copyInputs.feedProgram.length - 1].start.value =
        +copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value +
        1;
    }
    setInputs(copyInputs);
  };

  const removeLine = key => {
    const copyInputs = { ...inputs };
    copyInputs[key].pop();
    setInputs(copyInputs);
  };

  const inputHandler = (index, key, event, keyList) => {
    const copyInputs = { ...inputs };
    if (event.target.value === "0") {
    }
    if (index >= 0) {
      copyInputs[keyList] = [...inputs[keyList]];
      copyInputs[keyList][index][key].value =
        event.target.value === "" ? "" : +event.target.value;
    } else {
      copyInputs[key] = event.target.value === "" ? "" : +event.target.value;
    }
    setInputs(copyInputs);
  };

  const selectHandler = (index, key, event, keyList) => {
    const copyInputs = { ...inputs };
    copyInputs[key] = event.target.value;
    setInputs(copyInputs);
  };

  const generateReport = () => {
    const params = {
      a: 10.5,
      b: 40,
      MOmax: 6.04,
      criticalTemperature: 18,
      sd: 0.15,
      idealRelationship: {
        metCys: 82,
        thr: 69,
        trp: 21,
        arg: 104,
        val: 91,
        ile: 78,
        leu: 121,
        his: 29,
        pheTyr: 119,
        glySer: 77
      }
    };

    const response = {
      feedProgram: [],
      weeks: []
    };
    inputs.feedProgram.forEach(weekData => {
      for (
        let week = +weekData.start.value;
        week <= +weekData.end.value;
        week++
      ) {
        const responseWeek = {
          week: +week,
          temperature: weekData.temperature.value,
          bw: weekData.bodyWeight.value,
          eggOutput: weekData.eggOutput.value,
          lay: weekData.lay.value,
          feedIntake:weekData.feedIntake.value
        };
        responseWeek.gp =
          response.weeks.length > 0
            ? responseWeek.bw - response.weeks[response.weeks.length - 1].bw
            : 0;
        responseWeek.em =
          responseWeek.temperature >= params.criticalTemperature
            ? Math.pow(responseWeek.bw, 0.75) * 92.4 +
              0.88 * (responseWeek.temperature - params.criticalTemperature) +
              6.68 * responseWeek.gp +
              2.4 * responseWeek.eggOutput
            : Math.pow(responseWeek.bw, 0.75) * 92.4 +
              6.73 * (params.criticalTemperature - responseWeek.temperature) +
              6.68 * responseWeek.gp +
              2.4 * responseWeek.eggOutput;
        responseWeek.eggWeight =
          (responseWeek.eggOutput * 100) / responseWeek.lay;
        responseWeek.variationY = Math.sqrt(
          Math.pow(params.a, 2) * Math.pow(params.MOmax, 2) +
            Math.pow(params.b, 2) * Math.pow(params.sd, 2)
        );
        if (inputs.small && inputs.medium && inputs.large) {
          responseWeek.variationK =
            lys /
            1000000 /
            ((responseWeek.eggWeight <= 55
              ? inputs.small
              : responseWeek.eggWeight <= 60
              ? inputs.medium
              : inputs.large) /
              30 /
              12 /
              responseWeek.eggWeight);
        } else {
          responseWeek.variationK = 0;
        }
        responseWeek.variationZ = f.normSInv(
          1 - params.a * responseWeek.variationK
        ) || 0;
        responseWeek.cAAMediaMg =
          params.a * responseWeek.eggOutput + params.b * responseWeek.bw;
        responseWeek.cAAMediaPerc =
          responseWeek.cAAMediaMg / responseWeek.feedIntake / 10;

        responseWeek.cAAOtimoMg =
          responseWeek.cAAMediaMg +
          responseWeek.variationY * responseWeek.variationZ;
        responseWeek.cAAOtimoPerc =
          responseWeek.cAAOtimoMg / responseWeek.feedIntake / 10;
        responseWeek.cAAPercMg =
          responseWeek.cAAMediaMg +
          responseWeek.variationY *
            f.normSInv((inputs.populationRequirement / 100) || 1);
        responseWeek.cAAPercPerc =
          responseWeek.cAAPercMg / responseWeek.feedIntake / 10;
        response.weeks.push({ ...responseWeek });
      }
    });
    const dataAverage = {
      index: 0,
      average: {}
    };
    const keyLys = {
      average: "cAAMediaMg",
      maxProfit: "cAAOtimoMg",
      population: "cAAPercMg"
    };
    response.weeks.forEach((week, indexWeeks) => {
      if (
        week.week > inputs.feedProgram[dataAverage.index].end.value ||
        indexWeeks === response.weeks.length - 1
      ) {
        Object.keys(week).forEach(key => {
          dataAverage.average[key] =
            dataAverage.average[key] /
            (inputs.feedProgram[dataAverage.index].end.value -
              inputs.feedProgram[dataAverage.index].start.value +
              1);
        });
        dataAverage.average.lys =
          dataAverage.average[keyLys[inputs.metodology]];
        dataAverage.average.lysAverage =
          dataAverage.average.lys / dataAverage.average.feedIntake / 10;
        Object.keys(params.idealRelationship).forEach(key => {
          dataAverage.average[key] =
            dataAverage.average.lys * (params.idealRelationship[key] / 100);
          dataAverage.average[key + "Average"] =
            dataAverage.average[key] / dataAverage.average.feedIntake / 10;
        });
        dataAverage.average.start =
          inputs.feedProgram[dataAverage.index].start.value;
        dataAverage.average.end =
          inputs.feedProgram[dataAverage.index].end.value;
        dataAverage.average.emResult = 1000 * dataAverage.average.em / dataAverage.average.feedIntake;     
        response.feedProgram.push(dataAverage.average);
        dataAverage.index++;
        dataAverage.average = {};
      }
      Object.keys(week).forEach(key => {
        dataAverage.average[key] =
          (dataAverage.average[key] === undefined
            ? 0
            : dataAverage.average[key]) + week[key];
      });
    });
    setResult([...response.feedProgram]);
    setTabActiveIndex(2);
  };

  const tabs = [
    {
      label: "Inputs",
      component: (
        <FormInputs
          inputs={inputs}
          addLineFeedProgram={addLineFeedProgram}
          removeLine={removeLine}
          inputHandler={inputHandler}
          selectHandler={selectHandler}
          generateReport={generateReport}
        />
      )
    },
    {
      label: "Formulador",
      component: <Formulador setLys={setLys} />
    },
    {
      label: "Resultado",
      component: <Result result={result} />
    }
  ];

  return (
    <Fragment>
      <HeaderContent
        label="Programa Nutricional para Postura"
        links={[
          { label: "home", link: "/home" },
          { label: "Programa Nutricional para Postura", active: true }
        ]}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-md-12 text-left">
            <div className="ibox float-e-margins">
              <div className="ibox-content icons-box">
                <div className="row">
                  <div className="col-md-12 tabs-container">
                    <ul className="nav nav-tabs">
                      {tabs.map((tab, index) => (
                        <li className="nav-item" key={`tab${index}`}>
                          <span
                            className={`nav-link pointer ${
                              index === tabActiveIndex ? "active" : ""
                            }`}
                            onClick={() => setTabActiveIndex(index)}
                          >
                            {tab.label}
                          </span>
                        </li>
                      ))}
                    </ul>
                    {tabs.map((tab, index) => (
                      <div
                        key={`tab${index}`}
                        style={{
                          display: index === tabActiveIndex ? "block" : "none"
                        }}
                      >
                        {tab.component}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Lay;

import React from "react";
import utils from "../../../utils/functions";

const Results = (props) => {
  return (
    <div className="broiler resultados mt-3 ">
      <table className="table ">
        <thead className="thead-dark ">
          <tr>
            <th className="col">Fase</th>
            <th className="col">EM</th>
            <th className="col">CR</th>
            <th className="col" colSpan="2">
              Lys
            </th>
            <th className="col" colSpan="2">
              Met+Cys
            </th>
            <th className="col" colSpan="2">
              Thr
            </th>
            <th className="col" colSpan="2">
              Val
            </th>
            <th className="col" colSpan="2">
              Iso
            </th>
            <th className="col" colSpan="2">
              Trp
            </th>
          </tr>
          <tr>
            <th className="col">Dias</th>
            <th className="col">Kcal/kg Ração</th>
            <th className="col">g</th>
            <th className="col">mg</th>
            <th className="col">%</th>
            <th className="col">mg</th>
            <th className="col">%</th>
            <th className="col">mg</th>
            <th className="col">%</th>
            <th className="col">mg</th>
            <th className="col">%</th>
            <th className="col">mg</th>
            <th className="col">%</th>
            <th className="col">mg</th>
            <th className="col">%</th>
          </tr>
        </thead>
        <tbody>
          {props.result.map((item, index) => (
            <tr key={index}>
              <td>
                {item.start} a {item.end}
              </td>
              <td>
                {utils.filterMask("number", (1000 * item.me) / item.fi, 0)}
              </td>
              <td>{utils.filterMask("number", item.fi, 0)}</td>
              <td>{utils.filterMask("number", item.Lys, 0)}</td>
              <td>{utils.filterMask("number", item.LysP, 2)}</td>
              <td>{utils.filterMask("number", item.MetCys, 0)}</td>
              <td>{utils.filterMask("number", item.MetCysP, 2)}</td>
              <td>{utils.filterMask("number", item.Thr, 0)}</td>
              <td>{utils.filterMask("number", item.ThrP, 2)}</td>
              <td>{utils.filterMask("number", item.Val, 0)}</td>
              <td>{utils.filterMask("number", item.ValP, 2)}</td>
              <td>{utils.filterMask("number", item.Iso, 0)}</td>
              <td>{utils.filterMask("number", item.IsoP, 2)}</td>
              <td>{utils.filterMask("number", item.Trp, 0)}</td>
              <td>{utils.filterMask("number", item.TrpP, 2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Results;

import React, { Fragment, useState } from "react";
import "./index.css";
import FormInputs from "./FormInputs";
import Result from "./Result";
import HeaderContent from "../../components/HeaderContent";

const OvosFranga = () => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const initialInput = {
    weekData: {
      week: {
        value: "",
        label: "Semana"
      },
      temperature: {
        value: "",
        label: "Temperatura"
      },
      em: {
        value: "",
        label: "Energia Metabolizável"
      }
    },
    feedProgram: {
      start: {
        value: "",
        label: "Início"
      },
      end: {
        value: "",
        label: "Fim"
      },
      bodyWeigth: {
        value: "",
        label: "Peso Vivo"
      }
    }
  };

  const initialLineage = {
    hisexBranca: {
      bodyWeightWm: 1.3462,
      bodyWeightB: 0.0241,
      bodyWeightWi: 55.5,
      bodyProteinWm: 0.2414,
      bodyProteinB: 0.0269,
      bodyProteinWi: 51,
      featherProteinWm: 0.126,
      featherProteinB: 0.0285,
      featherProteinWi: 55,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    },
    hisexVermelha: {
      bodyWeightWm: 1.765,
      bodyWeightB: 0.0238,
      bodyWeightWi: 59.9,
      bodyProteinWm: 0.326,
      bodyProteinB: 0.0273,
      bodyProteinWi: 57,
      featherProteinWm: 0.142,
      featherProteinB: 0.0325,
      featherProteinWi: 56,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    },
    hyLineW36: {
      bodyWeightWm: 1.261,
      bodyWeightB: 0.0263,
      bodyWeightWi: 51.2,
      bodyProteinWm: 0.3076,
      bodyProteinB: 0.0222,
      bodyProteinWi: 62,
      featherProteinWm: 0.1445,
      featherProteinB: 0.0277,
      featherProteinWi: 54,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    },
    hyLineVermelha: {
      bodyWeightWm: 1.77,
      bodyWeightB: 0.0238,
      bodyWeightWi: 60.3,
      bodyProteinWm: 0.452,
      bodyProteinB: 0.0198,
      bodyProteinWi: 74,
      featherProteinWm: 0.153,
      featherProteinB: 0.0271,
      featherProteinWi: 56,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    },
    dekalbBranca: {
      bodyWeightWm: 1.4366,
      bodyWeightB: 0.0233,
      bodyWeightWi: 59,
      bodyProteinWm: 0.2583,
      bodyProteinB: 0.025,
      bodyProteinWi: 54.28,
      featherProteinWm: 0.09414,
      featherProteinB: 0.031,
      featherProteinWi: 53.28,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    },
    dekalbVermelha: {
      bodyWeightWm: 1.8087,
      bodyWeightB: 0.0243,
      bodyWeightWi: 59,
      bodyProteinWm: 0.303,
      bodyProteinB: 0.0271,
      bodyProteinWi: 52.32,
      featherProteinWm: 0.1123,
      featherProteinB: 0.026,
      featherProteinWi: 60.51,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    },
    lohmannLSL: {
      bodyWeightWm: 1.2533,
      bodyWeightB: 0.0246,
      bodyWeightWi: 54,
      bodyProteinWm: 0.23,
      bodyProteinB: 0.0257,
      bodyProteinWi: 52.84,
      featherProteinWm: 0.0902,
      featherProteinB: 0.03,
      featherProteinWi: 55.45,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    },
    lohmannVermelha: {
      bodyWeightWm: 1.8728,
      bodyWeightB: 0.0244,
      bodyWeightWi: 58,
      bodyProteinWm: 0.325,
      bodyProteinB: 0.026,
      bodyProteinWi: 55.37,
      featherProteinWm: 0.1287,
      featherProteinB: 0.025,
      featherProteinWi: 64.67,
      bodyFatWm: -1.471,
      bodyFatB: 1.208,
      bodyFatWi: 0,
      ovaryWeightWm: 0.0799,
      ovaryWeightB: 0.1107,
      ovaryWeightWi: 115,
      ovaryProteinWm: 0.014382,
      ovaryProteinB: 0.1107,
      ovaryProteinWi: 115,
      oviductWeightWm: 0.0632,
      oviductWeightB: 0.1755,
      oviductWeightWi: 115,
      oviductProteinWm: 0.01264,
      oviductProteinB: 0.1755,
      oviductProteinWi: 115
    }
  };

  const [inputs, setInputs] = useState({
    //weekData: [{ ...initialInput.weekData }],
    //feedProgram: [{ ...initialInput.feedProgram }],
    weekData: [
      { week: { value: 1 }, temperature: { value: 31 }, em: { value: 2.85 } },
      { week: { value: 2 }, temperature: { value: 28 }, em: { value: 2.9 } },
      { week: { value: 3 }, temperature: { value: 26 }, em: { value: 2.9 } },
      { week: { value: 4 }, temperature: { value: 23 }, em: { value: 2.9 } },
      { week: { value: 5 }, temperature: { value: 21 }, em: { value: 2.9 } },
      { week: { value: 6 }, temperature: { value: 19 }, em: { value: 2.9 } },
      { week: { value: 7 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 8 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 9 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 10 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 11 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 12 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 13 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 14 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 15 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 16 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 17 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 18 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 19 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 20 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 21 }, temperature: { value: 17 }, em: { value: 2.9 } },
      { week: { value: 22 }, temperature: { value: 17 }, em: { value: 2.9 } }
    ],
    feedProgram: [
      { start: { value: 1 }, end: { value: 4 }, bodyWeight: { value: 0.255 } },
      { start: { value: 5 }, end: { value: 10 }, bodyWeight: { value: 0.82 } },
      { start: { value: 11 }, end: { value: 15 }, bodyWeight: { value: 1.14 } },
      { start: { value: 16 }, end: { value: 18 }, bodyWeight: { value: 1.3 } }
    ],
    lineage: "hisexBranca",
    useBw: false
  });

  const [lineageData, setLineageData] = useState(initialLineage);

  const [result, setResult] = useState([]);

  const addLineWeek = () => {
    const copyInputs = { ...inputs };
    copyInputs.weekData.push({ ...initialInput.weekData });
    if (copyInputs.weekData[copyInputs.weekData.length - 2].week.value) {
      copyInputs.weekData[copyInputs.weekData.length - 1].week.value =
        +copyInputs.weekData[copyInputs.weekData.length - 2].week.value + 1;
    }
    setInputs(copyInputs);
  };

  const addLineFeedProgram = () => {
    const copyInputs = { ...inputs };
    copyInputs.feedProgram.push({ ...initialInput.feedProgram });
    if (copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value) {
      copyInputs.feedProgram[copyInputs.feedProgram.length - 1].start.value =
        +copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value +
        1;
    }
    setInputs(copyInputs);
  };

  const removeLine = key => {
    const copyInputs = { ...inputs };
    copyInputs[key].pop();
    setInputs(copyInputs);
  };

  const inputHandler = (index, key, event, keyList) => {
    const copyInputs = { ...inputs };
    if (index >= 0) {
      copyInputs[keyList] = [...inputs[keyList]];
      copyInputs[keyList][index][key].value = +event.target.value;
    } else {
      copyInputs[key] = event.target.value;
    }
    setInputs(copyInputs);
  };

  const checkboxHandler = (checked, key) => {
    const copyInputs = { ...inputs };
    copyInputs[key] = checked;
    setInputs(copyInputs);
  };

  const calcSQ = lastDayOfWeek => {
    const alometry = {
      a: {
        bwXpw: -1.6484,
        pwXfw: -1.471
      },
      b: {
        bwXpw: 0.9767,
        pwXfw: 1.208
      }
    };
    const dif = lastDayOfWeek.map((item, index) => {
      const bw = inputs.feedProgram[index].bodyWeight.value;
      const response = {
        pv: Math.pow(item.pv - bw, 2),
        ppc: Math.pow(
          2.71828,
          alometry.a.bwXpw + Math.log(bw) * alometry.b.bwXpw
        ),
        week: item.week
      };
      response.ppp = Math.pow(item.ppp - response.ppc * 0.28, 2);
      response.ppc = Math.pow(item.ppc - response.ppc, 2);
      return response;
    });
    return {
      bw: dif.map(item => item.pv).reduce((a, b) => a + b),
      pw: dif.map(item => item.ppc).reduce((a, b) => a + b),
      fw: dif.map(item => item.ppp).reduce((a, b) => a + b)
    };
  };

  const getResponseReport = lineage => {
    const response = {
      days: [],
      weeks: [],
      lastDayOfWeek: [],
      feedNutritionalProgram: [],
      output: []
    };
    const ltc = [31, 28, 26, 23, 21, 19];
    const idealRelashionship = {
      metCys: [73,78,67],
      thr: [67,68,69],
      trp: [18,20,22],
      arg: [108,108,108],
      val: [77,80,84],
      ile: [70,75,77],
      leu: [113,117,128],
      his: [37,38,39],
      pheTyr: [121,125,131],
      glySer: [78,133,124]
    };
    let day = 1;
    const feedDataAverage = {
      em: 0,
      cr: 0,
      lys: 0,
      days: 0
    };
   /*  const getIndexIR = (week)=>{
      if(week < 7){
        return 0
      }else if(week < 11){
        return 1
      }
      return 2
    } */
    inputs.weekData.forEach((weekData, index) => {
      const ltcWeek = ltc[index] ? ltc[index] : 17;
      const weekDataAverage = {
        em: 0,
        cr: 0,
        lys: 0
      };
      //const indexIR = getIndexIR(weekData.week.value)
      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        const dayData = {
          week: weekData.week.value,
          day,
          eu: weekData.week.value < 10 ? 65 : 60
        };
        dayData.pv =
          lineage.bodyWeightWm *
          Math.exp(
            -Math.exp(-lineage.bodyWeightB * (day - lineage.bodyWeightWi))
          );
        dayData.ppc =
          lineage.bodyProteinWm *
          Math.exp(
            -Math.exp(-lineage.bodyProteinB * (day - lineage.bodyProteinWi))
          );
        dayData.pd =
          lineage.bodyProteinB *
          dayData.ppc *
          Math.log(lineage.bodyProteinWm / dayData.ppc);
        dayData.ppp =
          lineage.featherProteinWm *
          Math.exp(
            -Math.exp(
              -lineage.featherProteinB * (day - lineage.featherProteinWi)
            )
          );
        dayData.ppd =
          lineage.featherProteinB *
          dayData.ppp *
          Math.log(lineage.featherProteinWm / dayData.ppp);
        dayData.pg =
          Math.exp(
            lineage.bodyFatWm + lineage.bodyFatB * Math.log(dayData.ppc * 1000)
          ) / 1000;
        dayData.gd = day === 1 ? 0 : dayData.pg - response.days[day - 2].pg;
        dayData.ovap =
          day < 99
            ? 0
            : lineage.ovaryWeightWm *
              Math.exp(
                -Math.exp(-lineage.ovaryWeightB * (day - lineage.ovaryWeightWi))
              );
        dayData.ovagp =
          day < 99
            ? 0
            : lineage.ovaryWeightB *
              dayData.ovap *
              Math.log(lineage.ovaryWeightWm / dayData.ovap);
        dayData.ovapp =
          day < 99
            ? 0
            : lineage.ovaryProteinWm *
              Math.exp(
                -Math.exp(
                  -lineage.ovaryProteinB * (day - lineage.ovaryProteinWi)
                )
              );
        dayData.ovapd =
          day < 99
            ? 0
            : lineage.ovaryProteinB *
              dayData.ovapp *
              Math.log(lineage.ovaryProteinWm / dayData.ovapp);
        dayData.ovip =
          day < 99
            ? 0
            : lineage.oviductWeightWm *
              Math.exp(
                -Math.exp(
                  -lineage.oviductWeightB * (day - lineage.oviductWeightWi)
                )
              );
        dayData.ovipp =
          day < 99
            ? 0
            : lineage.oviductProteinWm *
              Math.exp(
                -Math.exp(
                  -lineage.oviductProteinB * (day - lineage.oviductProteinWi)
                )
              );
        dayData.ovipd =
          day < 99
            ? 0
            : lineage.oviductProteinB *
              dayData.ovipp *
              Math.log(lineage.oviductProteinWm / dayData.ovipp);
        dayData.lys =
          ((0.075 * dayData.pd) / (dayData.eu / 100) +
            (0.018 * dayData.ppd) / (dayData.eu / 100)) *
            1000000 +
          Math.pow(lineage.bodyProteinWm, 0.73) *
            (dayData.ppc / lineage.bodyProteinWm) *
            151.2 +
          0.01 * dayData.ppp * 0.018 +
          ((0.075 * dayData.ovapd) / (dayData.eu / 100) +
            (0.075 * dayData.ovipd) / (dayData.eu / 100)) *
            1000000;

        dayData.mekcal =
          weekData.temperature.value >= ltcWeek
            ? Math.pow(dayData.pv, 0.75) * 92.4 +
              0.88 * (weekData.temperature.value - ltcWeek) +
              13.6 * (dayData.gd * 1000) +
              12.6 * (dayData.pd * 1000)
            : Math.pow(dayData.pv, 0.75) * 92.4 +
              6.73 * (ltcWeek - weekData.temperature.value) +
              13.6 * (dayData.gd * 1000) +
              12.6 * (dayData.pd * 1000);

        dayData.memjcal = weekData.em.value;
        dayData.fi = dayData.mekcal / dayData.memjcal;
        response.days.push(dayData);
        weekDataAverage.em += dayData.mekcal;
        weekDataAverage.cr += dayData.fi;
        weekDataAverage.lys += dayData.lys;
        feedDataAverage.em += dayData.mekcal;
        feedDataAverage.cr += dayData.fi;
        feedDataAverage.lys += dayData.lys;
        feedDataAverage.days++;
        if (
          dayOfWeek === 6 &&
          inputs.feedProgram.map(item => item.end.value).indexOf(dayData.week) >
            -1
        ) {
          const indexFeedPrograma = inputs.feedProgram
            .map(item => item.end.value)
            .indexOf(dayData.week);
          response.lastDayOfWeek.push({
            pv: dayData.pv,
            ppc: dayData.ppc,
            ppp: dayData.ppp,
            week: dayData.week
          });
          const feedNutritionalProgram = {
            em: feedDataAverage.em / feedDataAverage.days,
            cr: feedDataAverage.cr / feedDataAverage.days,
            lys: feedDataAverage.lys / feedDataAverage.days
          };
          Object.keys(idealRelashionship).forEach(key => {
            feedNutritionalProgram[key] =
              (feedNutritionalProgram.lys * idealRelashionship[key][0]) / 100;
          });
          feedNutritionalProgram.start =
            inputs.feedProgram[indexFeedPrograma].start.value;
          feedNutritionalProgram.end =
            inputs.feedProgram[indexFeedPrograma].end.value;
          response.feedNutritionalProgram.push(feedNutritionalProgram);
          const totalDays =
            (feedNutritionalProgram.end - feedNutritionalProgram.start + 1) * 7;
          const averageLys = feedDataAverage.lys / totalDays;
          const averageCr = feedDataAverage.cr / totalDays;
          const output = {
            lys: averageLys / (averageCr * 0.95) / 10,
            start: feedNutritionalProgram.start,
            end: feedNutritionalProgram.end
          };
          Object.keys(idealRelashionship).forEach(key => {
            output[key] =
              feedNutritionalProgram[key] /
              (feedNutritionalProgram.cr * 0.95) /
              10;
          });
          response.output.push(output);
          feedDataAverage.em = 0;
          feedDataAverage.cr = 0;
          feedDataAverage.lys = 0;
          feedDataAverage.days = 0;
        }
        day++;
      }
      weekDataAverage.em = weekDataAverage.em / 7;
      weekDataAverage.cr = weekDataAverage.cr / 7;
      weekDataAverage.lys = weekDataAverage.lys / 7 / weekDataAverage.cr / 10;
      Object.keys(idealRelashionship).forEach(key => {
        weekDataAverage[key] =
          (weekDataAverage.lys * idealRelashionship[key][0]) / 100;
      });
      weekDataAverage.week = weekData.week.value;
      response.weeks.push(weekDataAverage);
    });

    return response;
  };

  const generateReport = () => {
    let lineage = { ...lineageData[inputs.lineage] };
    let response = getResponseReport(lineage);
    if (inputs.useBw) {
      const variation = {
        bodyWeightWm: { min: 1, max: 2 },
        bodyWeightB: { min: 0.01, max: 0.03 },
        bodyProteinWm: { min: 0.2, max: 0.4 },
        bodyProteinB: { min: 0.015, max: 0.035 },
        featherProteinWm: { min: 0.07, max: 0.15 },
        featherProteinB: { min: 0.02, max: 0.04 }
      };
      let bestSQ = calcSQ(response.lastDayOfWeek);
      let indexLineage = 0;
      const arrayLineage = [];
      for (let i = 0; i < 10; i++) {
        arrayLineage.push({ ...lineage });
        arrayLineage[i].bodyWeightWm =
          variation.bodyWeightWm.min +
          ((variation.bodyWeightWm.max - variation.bodyWeightWm.min) / 10) * i;
        arrayLineage[i].bodyWeightB =
          variation.bodyWeightB.min +
          ((variation.bodyWeightB.max - variation.bodyWeightB.min) / 10) * i;
        response = getResponseReport(arrayLineage[i]);
        const sq = calcSQ(response.lastDayOfWeek);
        if (sq.bw < bestSQ.bw) {
          bestSQ = { ...sq };
          indexLineage = i;
        }
      }
      lineage = { ...arrayLineage[indexLineage] };
      for (let i = 0; i < 10; i++) {
        arrayLineage[i] = { ...lineage };
        arrayLineage[i].bodyProteinWm =
          variation.bodyProteinWm.min +
          ((variation.bodyProteinWm.max - variation.bodyProteinWm.min) / 10) *
            i;
        arrayLineage[i].bodyProteinB =
          variation.bodyProteinB.min +
          ((variation.bodyProteinB.max - variation.bodyProteinB.min) / 10) * i;
        response = getResponseReport(arrayLineage[i]);
        const sq = calcSQ(response.lastDayOfWeek);
        if (sq.pw < bestSQ.pw) {
          bestSQ = { ...sq };
          indexLineage = i;
        }
      }
      lineage = { ...arrayLineage[indexLineage] };
      for (let i = 0; i < 10; i++) {
        arrayLineage[i] = { ...lineage };
        arrayLineage[i].featherProteinWm =
          variation.featherProteinWm.min +
          ((variation.featherProteinWm.max - variation.featherProteinWm.min) /
            10) *
            i;
        arrayLineage[i].featherProteinB =
          variation.featherProteinB.min +
          ((variation.featherProteinB.max - variation.featherProteinB.min) /
            10) *
            i;
        response = getResponseReport(arrayLineage[i]);
        const sq = calcSQ(response.lastDayOfWeek);
        if (sq.fw < bestSQ.fw) {
          bestSQ = { ...sq };
          indexLineage = i;
        }
      }
      response = getResponseReport(arrayLineage[indexLineage]);
      const copyLineageData = { ...lineageData };
      copyLineageData[inputs.lineage] = arrayLineage[indexLineage];
      setLineageData(copyLineageData);
    }
    setResult([...response.output]);
    setTabActiveIndex(1);
  };

  const tabs = [
    {
      label: "Inputs",
      component: (
        <FormInputs
          inputs={inputs}
          addLineWeek={addLineWeek}
          addLineFeedProgram={addLineFeedProgram}
          removeLine={removeLine}
          inputHandler={inputHandler}
          generateReport={generateReport}
          lineageData={lineageData}
          checkboxHandler={checkboxHandler}
        />
      )
    },
    {
      label: "Resultado",
      component: <Result result={result} />
    }
  ];

  return (
    <Fragment>
      <HeaderContent
        label="Programa Nutricional para Franga"
        links={[
          { label: "home", link: "/home" },
          { label: "Programa Nutricional para Franga", active: true }
        ]}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-md-12 text-left">
            <div className="ibox float-e-margins">
              <div className="ibox-content icons-box">
                <div className="row">
                  <div className="col-md-12 tabs-container">
                    <ul className="nav nav-tabs">
                      {tabs.map((tab, index) => (
                        <li className="nav-item" key={`tab${index}`}>
                          <span
                            className={`nav-link pointer ${
                              index === tabActiveIndex ? "active" : ""
                            }`}
                            onClick={() => setTabActiveIndex(index)}
                          >
                            {tab.label}
                          </span>
                        </li>
                      ))}
                    </ul>
                    {tabs.map((tab, index) => (
                      <div
                        key={`tab${index}`}
                        style={{
                          display: index === tabActiveIndex ? "block" : "none"
                        }}
                      >
                        {tab.component}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OvosFranga;

import React from "react";
import Checkbox from "../../../components/Checkbox";

const FormInputs = (props) => {
  return (
    <form
      className="form-inputs-postura"
      onSubmit={(event) => {
        event.preventDefault();
        props.generateReport();
      }}
    >
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mt-3">
            <label>Linhagem</label>
            <select
              className="form-control form-control-sm"
              value={props.inputs.lineage}
              onChange={(event) => props.inputHandler(-1, "lineage", event)}
            >
              <option value="">Selecione a Linhagem</option>
              <option value="hisexBranca">Hisex Branca</option>
              <option value="hisexVermelha">Hisex Vermelha</option>
              <option value="hyLineW36">Hy Line W36</option>
              <option value="hyLineVermelha">Hy Line Vermelha</option>
              <option value="dekalbBranca">Dekalb Branca</option>
              <option value="dekalbVermelha">Dekalb Vermelha</option>
              <option value="lohmannLSL">Lohmann LSL</option>
              <option value="lohmannVermelha">Lohmann Vermelha</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mt-3">
            <div className="form-check">
              <Checkbox
                label="Utilizar Peso Vivo Observado"
                checked={props.inputs.useBw}
                onChange={(checked) => props.checkboxHandler(checked, "useBw")}
              />
            </div>
          </div>
        </div>
      </div>
      <table className="table">
        <thead className="thead-dark form-inputs-postura__header">
          <tr>
            <th
              className="col form-inputs-postura__header--fixed text-center"
              colSpan="3"
            >
              Programa Nutricional
            </th>
          </tr>
          <tr>
            <th className="col form-inputs-postura__header--fixed">
              Início (Semana)
            </th>
            <th className="col form-inputs-postura__header--fixed">
              Fim (Semana)
            </th>
            <th className="col form-inputs-postura__header--fixed">
              Peso Vivo (kg)
            </th>
          </tr>
        </thead>
        <tbody>
          {props.inputs.feedProgram.map((input, indexRow) => (
            <tr key={`row${indexRow}`}>
              {Object.keys(input).map((key, indexColumn) => (
                <td key={`column${indexColumn}`}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={input[key].label}
                    value={input[key].value}
                    onChange={(event) =>
                      props.inputHandler(indexRow, key, event, "feedProgram")
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="8">
              <div className="form-inputs-postura__footer d-flex justify-content-end">
                {props.inputs.feedProgram.length > 1 ? (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm mr-1"
                    onClick={() => props.removeLine("feedProgram")}
                  >
                    Remover linha
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={props.addLineFeedProgram}
                >
                  Adicionar Linha
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table">
        <thead className="thead-dark form-inputs-postura__header">
          <tr>
            <th className="col form-inputs-postura__header--fixed">Semana</th>
            <th className="col form-inputs-postura__header--fixed">
              Temperatura (ºC)
            </th>
            <th className="col form-inputs-postura__header--fixed">
              Energia Metabolizável (Mcal/kg de Dieta)
            </th>
          </tr>
        </thead>
        <tbody>
          {props.inputs.weekData.map((input, indexRow) => (
            <tr key={`row${indexRow}`}>
              {Object.keys(input).map((key, indexColumn) => (
                <td key={`column${indexColumn}`}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={input[key].label}
                    value={input[key].value}
                    onChange={(event) =>
                      props.inputHandler(indexRow, key, event, "weekData")
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
          <tr>
            <td colSpan="8">
              <div className="form-inputs-postura__footer d-flex justify-content-end">
                {props.inputs.weekData.length > 1 ? (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm mr-1"
                    onClick={() => props.removeLine("weekData")}
                  >
                    Remover linha
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={props.addLineWeek}
                >
                  Adicionar Linha
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="row">
        <div className="colmd-12 text-center">
          <button type="submit" className="btn btn-primary btn-sm ">
            Gerar Relatório
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormInputs;

const nutrients = [
  {
    nome: 'Dry Matter (%)',
    nomept:'Matéria Seca (%)', change:true,
    nomeen:'Dry Matter (%)',
    nomees:'Materia Seca (%)',
    nomeDB: 'materiaSeca',
    tipo: 'c'
  },
  {
    nome: 'Crude Fiber',
    nomept:'Fibra Bruta (%)', change:true,
    nomeen:'Crude Fiber (%)',
    nomees:'Fibra Cruda (%)',
    nomeDB: 'crudFiber',
    tipo: 'c'
  },
  {
    nome: 'Water HC',
    nomept:'Capacidade de Ret. de Água', change:true,
    nomeen:'Water HC',
    nomees:'Capacidad de Ret. de Agua',
    nomeDB: 'waterHC',
    tipo: 'c'
  },
  {
    nome: 'NDF',
    nomept:'FDN', change:true,
    nomeen:'NDF',
    nomees:'FDN',
    nomeDB: 'fdn',
    tipo: 'c'
  },
  {
    nome: 'ADF',
    nomept:'FDA', change:true,
    nomeen:'ADF',
    nomees:'FDA',
    nomeDB: 'fda',
    tipo: 'c'
  },
  {
    nome: 'Crude Protein (CP) (%)',
    nomept:'Proteína Bruta (PB) (%)', change:false,
    nomeen:'Crude Protein (CP) (%)',
    nomees:'Proteína Cruda (PC) (%)',
    nomeDB: 'proteinaBrutaPB',
    tipo: 'c',
  },
  {
    nome: 'Coef. Dig. CP (%)',
    nomept:'Coef. Dig. PB (%)', change:true,
    nomeen:'Coef. Dig. CP (%)',
    nomees:'Coef. Dig. PC (%)',
    nomeDB: 'coefDigPBAves',
    tipo: 'c'
  },
  {
    nome: 'Digestible CP (%)',
    nomept:'PB Digestível (%)', change:true,
    nomeen:'Digestible CP (%)',
    nomees:'PC Digestible (%)',
    nomeDB: 'pBDigestivelAves',
    tipo: 'c'
  },
  {
    nome: 'Gross Energy (Kcal/kg)',
    nomept:'Energia Bruta (Kcal/kg)', change:true,
    nomeen:'Gross Energy (Kcal/kg)',
    nomees:'Energia Cruda (Kcal/kg)',
    nomeDB: 'energiaBruta',
    tipo: 'c'
  },
  {
    nome: 'Met. Energia (Kcal/kg)',
    nomept:'Energia Met. (Kcal/kg)', change:false,
    nomeen:'Met. Energy (Kcal/kg)',
    nomees:'Energia Met. (Kcal/kg)',
    nomeDB: 'energiaMetAves',
    tipo: 'c'
  },
  {
    nome: 'True Met. Energy Verd. (Kcal/kg)',
    nomept:'Energia Met. Verd. (Kcal/kg)', change:true,
    nomeen:'True Met. Energy (Kcal/kg)',
    nomees:'Energia Met. Verd. (Kcal/kg)',
    nomeDB: 'energiaMetVerdAves',
    tipo: 'c'
  },
  {
    nome: 'Effective Energy (MJ/kg)',
    nomept:'Energia Efetiva (MJ/kg)', change:true,
    nomeen:'Effective Energy (MJ/kg)',
    nomees:'Energia Efectiva (MJ/kg)',
    nomeDB: 'energiaEfetivaAves',
    tipo: 'c'
  },
  {
    nome: 'Fat (%)',
    nomept:'Gordura (%)', change:true,
    nomeen:'Fat (%)',
    nomees:'Grassa (%)',
    nomeDB: 'gordura',
    tipo: 'c'
  },
  {
    nome: 'Dig. Coef. Fat (%)',
    nomept:'Coef. Dig. Gordura (%)', change:true,
    nomeen:'Dig. Coef. Fat (%)',
    nomees:'Coef. Dig. Grassa (%)',
    nomeDB: 'coefDigGordura',
    tipo: 'c'
  },
  {
    nome: 'Dig. Fat (%)',
    nomept:'Dig. Gordura (%)', change:true,
    nomeen:'Dig. Fat (%)',
    nomees:'Dig. Grassa (%)',
    nomeDB: 'gorduraDigAves',
    tipo: 'c'
  },
  {
    nome: 'Organic Matter (OM) (%)',
    nomept:'Matéria Orgânica (MO) (%)', change:true,
    nomeen:'Organic Matter (OM) (%)',
    nomees:'Materia Orgánica (MO) (%)',
    nomeDB: 'materiaOrganica',
    tipo: 'c'
  },
  {
    nome: 'Mineral (%)',
    nomept:'Mineral (%)', change:true,
    nomeen:'Mineral (%)',
    nomees:'Mineral (%)',
    nomeDB: 'materiaMineral',
    tipo: 'c'
  },
  {
    nome: 'Potassium (%)',
    nomept:'Potássio (%)', change:true,
    nomeen:'Potassium (%)',
    nomees:'Potasio (%)',
    nomeDB: 'potassio',
    tipo: 'c'
  },
  {
    nome: 'Sodium (%)',
    nomept:'Sódio (%)', change:false,
    nomeen:'Sodium (%)',
    nomees:'Sodio (%)',
    nomeDB: 'sodio',
    tipo: 'c'
  },
  {
    nome: 'Chloride (%)',
    nomept:'Clóro (%)', change:true,
    nomeen:'Chloride (%)',
    nomees:'Cloro (%)',
    nomeDB: 'cloro',
    tipo: 'c'
  },
  {
    nome: 'Eletrolitic Balance (mEq/kg)',
    nomept:'Balanço Eletrolítico (mEq/kg)', change:true,
    nomeen:'Eletrolitic Balance (mEq/kg)',
    nomees:'Equilibrio Electrolítico (mEq/kg)',
    nomeDB: 'balancoEletrolico',
    tipo: 'c'
  },
  {
    nome: 'total Phosphorus (%)',
    nomept:'Fósforo total (%)', change:true,
    nomeen:'total Phosphorus (%)',
    nomees:'Fosforo total (%)',
    nomeDB: 'pTotal',
    tipo: 'c'
  },
  {
    nome: 'Available Phosphorus (%)',
    nomept:'Fósforo disponível (%)', change:false,
    nomeen:'Available Phosphorus (%)',
    nomees:'Fósforo disponível (%)',
    nomeDB: 'pDisp',
    tipo: 'c'
  },
  {
    nome: 'Calcium (%)',
    nomept:'Cálcio (%)', change:false,
    nomeen:'Calcium (%)',
    nomees:'Cálcio (%)',
    nomeDB: 'ca',
    tipo: 'c'
  },
  {
    nome: 'Digestible Lysine (%)',
    nomept:'Lisina Digestível (%)', change:true,
    nomeen:'Digestible Lysine (%)',
    nomees:'Lisina Digestible (%)',
    nomeDB: 'lisinaDig',
    tipo: 'a'
  }
  /* {
    nome: 'total Lysina (%)',
    nomept:'Lisina total (%)', change:true,
    nomeen:'total Lysina (%)',
    nomees:'Lisina total (%)',
    nomeDB: 'lisinaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Methionina (%)',
    nomept:'Metionina total (%)', change:true,
    nomeen:'total Methionina (%)',
    nomees:'Metionina total (%)',
    nomeDB: 'metioninaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Met + Cys (%)',
    nomept:'Met + Cis total (%)', change:true,
    nomeen:'total Met + Cys (%)',
    nomees:'Met + Cis total (%)',
    nomeDB: 'metCisTotal',
    tipo: 'a'
  },
  {
    nome: 'total Threonine (%)',
    nomept:'Treonina total (%)', change:true,
    nomeen:'total Threonine (%)',
    nomees:'Treonina total (%)',
    nomeDB: 'treoninaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Tryptophan (%)',
    nomept:'Triptofano total (%)', change:true,
    nomeen:'total Tryptophan (%)',
    nomees:'Triptofano total (%)',
    nomeDB: 'triptofanoTotal',
    tipo: 'a'
  },
  {
    nome: 'total Arginine (%)',
    nomept:'Arginina total (%)', change:true,
    nomeen:'total Arginine (%)',
    nomees:'Arginina total (%)',
    nomeDB: 'argininaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Gli + Ser (%)',
    nomept:'Gli + Ser total (%)', change:true,
    nomeen:'total Gli + Ser (%)',
    nomees:'Gli + Ser total (%)',
    nomeDB: 'gliSerTotal',
    tipo: 'a'
  },
  {
    nome: 'total Valine (%)',
    nomept:'Valina total (%)', change:true,
    nomeen:'total Valine (%)',
    nomees:'Valina total (%)',
    nomeDB: 'valinaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Isoleucine (%)',
    nomept:'Isoleucina total (%)', change:true,
    nomeen:'total Isoleucine (%)',
    nomees:'Isoleucina total (%)',
    nomeDB: 'isoleucinaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Leucine (%)',
    nomept:'Leucina total (%)', change:true,
    nomeen:'total Leucine (%)',
    nomees:'Leucina total (%)',
    nomeDB: 'leucinaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Histidine (%)',
    nomept:'Histidina total (%)', change:true,
    nomeen:'total Histidine (%)',
    nomees:'Histidina total (%)',
    nomeDB: 'histidinaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Phenilalanine (%)',
    nomept:'Fenilalanina total (%)', change:true,
    nomeen:'total Phenilalanine (%)',
    nomees:'Fenilalanina total (%)',
    nomeDB: 'felaninaTotal',
    tipo: 'a'
  },
  {
    nome: 'total Phen +Tyr (%)',
    nomept:'Fen +Tir total (%)', change:true,
    nomeen:'total Phen +Tyr (%)',
    nomees:'Fen +Tir total (%)',
    nomeDB: 'fenTirTotal',
    tipo: 'a'
  },
  {
    nome: 'Digestible Lysine (%)',
    nomept:'Lisina Digestível (%)', change:true,
    nomeen:'Digestible Lysine (%)',
    nomees:'Lisina Digestible (%)',
    nomeDB: 'lisinaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Methionine (%)',
    nomept:'Metionina Digestível (%)', change:true,
    nomeen:'Digestible Methionine (%)',
    nomees:'Metionina Digestible (%)',
    nomeDB: 'metioninaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Met + Cys (%)',
    nomept:'Met + Cis Digestível (%)', change:false,
    nomeen:'Digestible Met + Cys (%)',
    nomees:'Met + Cis Digestible (%)',
    nomeDB: 'metCisDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Treonine (%)',
    nomept:'Treonina Digestível (%)', change:false,
    nomeen:'Digestible Treonine (%)',
    nomees:'Treonina Digestible (%)',
    nomeDB: 'treoninaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Tryptophan (%)',
    nomept:'Triptofano Digestível (%)', change:true,
    nomeen:'Digestible Tryptophan (%)',
    nomees:'Triptofano Digestible (%)',
    nomeDB: 'triptofanoDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Arginine (%)',
    nomept:'Arginina Digestível (%)', change:true,
    nomeen:'Digestible Arginine (%)',
    nomees:'Arginina Digestible (%)',
    nomeDB: 'argininaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Gli + Ser (%)',
    nomept:'Gli + Ser Digestível (%)', change:true,
    nomeen:'Digestible Gli + Ser (%)',
    nomees:'Gli + Ser Digestible (%)',
    nomeDB: 'gliSerDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Valine (%)',
    nomept:'Valina Digestível (%)', change:false,
    nomeen:'Digestible Valine (%)',
    nomees:'Valina Digestible (%)',
    nomeDB: 'valinaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Isoleucine (%)',
    nomept:'Isoleucina Digestível (%)', change:true,
    nomeen:'Digestible Isoleucine (%)',
    nomees:'Isoleucina Digestible (%)',
    nomeDB: 'isoleucinaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Leucine (%)',
    nomept:'Leucina Digestível (%)', change:true,
    nomeen:'Digestible Leucine (%)',
    nomees:'Leucina Digestible (%)',
    nomeDB: 'leucinaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Histidine (%)',
    nomept:'Histidina Digestível (%)', change:true,
    nomeen:'Digestible Histidine (%)',
    nomees:'Histidina Digestible (%)',
    nomeDB: 'histidinaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Phenilalanine (%)',
    nomept:'Fenilalanina Digestível (%)', change:true,
    nomeen:'Digestible Phenilalanine (%)',
    nomees:'Fenilalanina Digestible (%)',
    nomeDB: 'felaninaDig',
    tipo: 'a'
  },
  {
    nome: 'Digestible Phen +Tyr (%)',
    nomept:'Fen + Tir Digestível (%)', change:true,
    nomeen:'Digestible Phen +Tyr (%)',
    nomees:'Fen + Tir Digestible (%)',
    nomeDB: 'fenTirDig',
    tipo: 'a'
  } */
]

export default nutrients;
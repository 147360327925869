import React, { useState, useEffect } from "react";
import TopNavBar from "./TopNavBar";
import SideNavBar from "./SideNavBar";
import Content from "./Content";
import Footer from "./Footer";
import DropdownContext from "../../contexts/dropdownContext";
import UserProfileContext from "../../contexts/userProfileContext";
import api from "../../services/api";
import { isAuthenticated } from "../../services/auth";
import { Redirect } from "react-router-dom";

const Application = () => {
  const [showDropdown, setShowDropdown] = useState({
    userProfile: false,
    options: false
  });

  const [userProfileData, setUserProfileData] = useState({});

  const dropdownHandler = param => {
    const copyShowDropdown = { ...showDropdown };
    copyShowDropdown[param] = !copyShowDropdown[param];
    setTimeout(() => {
      setShowDropdown(copyShowDropdown);
    }, 100);
  };

  useEffect(() => {
    const loadUser = async () => {
      const response = await api.get("/profile").catch(err => err);
      if (!response.data) {
        return console.log(response.message);
      }
      setUserProfileData(response.data);
    };
    loadUser();
  }, []);

  return isAuthenticated() ? (
    <UserProfileContext.Provider value={userProfileData}>
      <DropdownContext.Provider
        value={{
          showDropdown,
          dropdownHandler
        }}
      >
        <div
          id="wrapper"
          onClick={() => {
            const copyShowDropdown = {};
            Object.keys(showDropdown).map(
              key => (copyShowDropdown[key] = false)
            );
            setShowDropdown(copyShowDropdown);
          }}
        >
          <SideNavBar />
          <div id="page-wrapper" className="gray-bg">
            <TopNavBar />
            <Content />
            <Footer />
          </div>
        </div>
      </DropdownContext.Provider>
    </UserProfileContext.Provider>
  ) : (
    <Redirect to="/login" />
  );
};

export default Application;

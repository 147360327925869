import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../services/api";
import logo from "../../assets/img/lavinesp.png";
import ReactLoading from "react-loading";
import "./index.css";

const ChangePass = props => {
  const erroMessageInitialState = {
    message: "",
    password: false,
    passwordConfirmation: false
  };

  const [form, setForm] = useState({
    password: "",
    passwordConfirmation: ""
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(erroMessageInitialState);

  const inputFormHandler = (value, key) => {
    const copyForm = { ...form };
    copyForm[key] = value;
    setForm(copyForm);
  };

  const submitData = async e => {
    e.preventDefault();
    if (!form.password || !form.passwordConfirmation) {
      setTimeout(() => {
        setErrorMessage(erroMessageInitialState);
      }, 4000);
      return setErrorMessage({
        message: "Preencha os campos Obrigatórios!",
        password: !form.password,
        passwordConfirmation: !form.passwordConfirmation
      });
    }
    if (form.password !== form.passwordConfirmation) {
      setTimeout(() => {
        setErrorMessage(erroMessageInitialState);
      }, 4000);
      return setErrorMessage({
        message: "As Senhas não coincidem!",
        password: true,
        passwordConfirmation: true
      });
    }
    setLoading(true);
    const response = await api
      .put("/auth/createpassword/" + props.match.params.token, form)
      .catch(err => err);
    setLoading(false);
    if (!response.data) {
      return Swal.fire("Criar Senha", "Token expirado ou inválido", "error");
    }
    Swal.fire("Criar Senha", "Senha criada com sucesso", "success");
    props.history.push("/login");
  };

  const year = new Date().getFullYear();
  return (
    <div className="change-pass">
      <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
          <div className="change-pass__logo">
            <img className="img-responsive" src={logo} alt="logo" />
          </div>
          <h3>Crie uma nova senha</h3>
          <form className="m-t" onSubmit={submitData}>
            <div
              className={`form-group ${
                errorMessage.password ? "has-error" : ""
              }`}
            >
              <input
                type="password"
                className="form-control"
                placeholder="Digite sua nova senha"
                required=""
                value={form.password}
                onChange={e => inputFormHandler(e.target.value, "password")}
              />
            </div>
            <div
              className={`form-group ${
                errorMessage.passwordConfirmation ? "has-error" : ""
              }`}
            >
              <input
                type="password"
                className="form-control"
                placeholder="Digite a senha novamente"
                required=""
                value={form.passwordConfirmation}
                onChange={e =>
                  inputFormHandler(e.target.value, "passwordConfirmation")
                }
              />
            </div>
            {errorMessage.message ? (
              <div className="alert alert-danger">{errorMessage.message}</div>
            ) : null}

            <button
              type="submit"
              className="btn btn-primary block full-width m-b"
              disabled={loading}
            >
              {loading ? (
                <div className="change-pass__spin">
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={19}
                    width={19}
                  />
                </div>
              ) : (
                "Enviar"
              )}
            </button>

            <a href="/">
              <small>Voltar</small>
            </a>
          </form>
          <p className="m-t">
            {" "}
            <small>Desenvolvido por Rafael Caseiro &copy; {year}</small>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChangePass);

import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import DropdownContext from "../../../contexts/dropdownContext";
import UserProfileContext from "../../../contexts/userProfileContext";
import { logout } from "../../../services/auth";

const SideNavBar = (props) => {
  const dropdownContext = useContext(DropdownContext);

  const userProfileContext = useContext(UserProfileContext);

  const [menu, setMenu] = useState([
    {
      label: "Home",
      icon: "home",
      active: false,
      link: "/",
    },
    {
      label: "Administração",
      icon: "wrench",
      link: "#",
      active: false,
      module: "admin",
      subMenu: [
        {
          label: "Usuários",
          icon: "users",
          active: false,
          link: "/administracao/usuarios/1/30/-created/%7B%7D",
          module: "user",
        },
      ],
    },
    {
      label: "Ferramentas Práticas (Programa Nutricional)",
      icon: "line-chart",
      link: "#",
      active: false,
      module: "programas",
      subMenu: [
        {
          label: "Frangos de Corte",
          icon: "line-chart",
          active: false,
          link: "/previsoes-frango-de-corte",
          module: "broiler",
        },
        {
          label: "Postura",
          icon: "area-chart",
          active: false,
          link: "/previsoes-postura",
          module: "lay",
        },
        {
          label: "Franga",
          icon: "area-chart",
          active: false,
          link: "/previsoes-franga",
          module: "pullet",
        },
      ],
    },
    {
      label: "Modelos de Simulação",
      icon: "area-chart",
      link: "#",
      active: false,
      module: "modelos",
      subMenu: [
        {
          label: "Broiler Growth Model",
          icon: "arrow-right",
          active: false,
          alias: true,
          link: "https://bgm.poultrymodel.com/",
          module: "bgm",
        },
        {
          label: "Egg Production Model",
          icon: "arrow-right",
          active: false,
          alias: true,
          link: "https://epm.poultrymodel.com/",
          module: "epm",
        },
      ],
    },
    {
      label: "Outros Modelos",
      icon: "bar-chart",
      link: "#",
      active: false,
      module: "programas",
      subMenu: [
        {
          label: "Tilápias",
          icon: "bar-chart",
          active: false,
          link: "/previsoes-tilapias",
          module: "fish",
        },
      ],
    },
  ]);

  const [currentLink, setCurrentLink] = useState("");

  const menuHandler = (indexMenu) => {
    setMenu(
      menu.map((item, index) => {
        const itemMenu = { ...item };
        itemMenu.active =
          (indexMenu === index && !item.subMenu) ||
          (indexMenu === index && !item.active && item.subMenu);
        return itemMenu;
      })
    );
  };

  useEffect(() => {
    if (currentLink !== props.location.pathname) {
      setCurrentLink(props.location.pathname);
      setMenu(
        menu.map((item) => {
          const itemMenu = { ...item };
          if (!item.subMenu) {
            itemMenu.active = props.location.pathname === itemMenu.link;
          } else {
            itemMenu.subMenu = itemMenu.subMenu.map((itemSubMenu) => {
              itemSubMenu.active = props.location.pathname === itemSubMenu.link;
              if (itemSubMenu.active) {
                itemMenu.active = true;
              }
              return itemSubMenu;
            });
          }
          return itemMenu;
        })
      );
    }
  }, [props.location.pathname, menu, currentLink]);
  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div
              className={`dropdown profile-element ${
                dropdownContext.showDropdown.userProfile ? "open" : ""
              }`}
            >
              <div className="dropdown-toggle ">
                <span className="clear">
                  <span className="block m-t-xs">
                    <strong className="font-bold">
                      {userProfileContext.name}
                    </strong>
                  </span>
                  <span
                    className="text-muted text-xs block pointer"
                    onClick={() => {
                      dropdownContext.dropdownHandler("userProfile");
                    }}
                  >
                    {userProfileContext.responsibility}{" "}
                    <b className="caret"></b>
                  </span>
                </span>
              </div>
              <ul className="dropdown-menu animated fadeInRight m-t-xs">
                <li>
                  <div className="dropdown-menu_link" onClick={logout}>
                    Logout
                  </div>
                </li>
              </ul>
            </div>
          </li>
          {userProfileContext._id
            ? menu.map((itemMenu, indexMenu) =>
                userProfileContext.permissions.indexOf(itemMenu.module) > -1 ||
                !itemMenu.module ||
                userProfileContext.roles.indexOf("admin") > -1 ? (
                  <li
                    className={itemMenu.active ? "active" : ""}
                    key={itemMenu.label + indexMenu}
                  >
                    {itemMenu.alias ? (
                      <a
                        href={itemMenu.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={`fa fa-${itemMenu.icon}`}></i>
                        <span className="nav-label">{itemMenu.label}</span>
                        {itemMenu.subMenu ? (
                          <span className="fa arrow"></span>
                        ) : null}
                      </a>
                    ) : (
                      <Link
                        to={itemMenu.link}
                        onClick={() => menuHandler(indexMenu)}
                      >
                        <i className={`fa fa-${itemMenu.icon}`}></i>
                        <span className="nav-label">{itemMenu.label}</span>
                        {itemMenu.subMenu ? (
                          <span className="fa arrow"></span>
                        ) : null}
                      </Link>
                    )}
                    {itemMenu.subMenu ? (
                      <ul
                        className={`nav nav-second-level collapse ${
                          itemMenu.active ? "in" : ""
                        }`}
                      >
                        {itemMenu.subMenu.map((itemSubMenu, indexSubMenu) =>
                          userProfileContext.permissions.indexOf(
                            itemSubMenu.module
                          ) > -1 ||
                          userProfileContext.roles.indexOf("admin") > -1 ? (
                            <li
                              key={itemSubMenu.label + indexSubMenu}
                              className={itemSubMenu.active ? "active" : ""}
                            >
                              {itemSubMenu.alias ? (
                                <a
                                  href={itemSubMenu.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className={`fa fa-${itemSubMenu.icon}`}
                                  ></i>
                                  <span className="nav-label">
                                    {itemSubMenu.label}
                                  </span>
                                  {itemSubMenu.subMenu ? (
                                    <span className="fa arrow"></span>
                                  ) : null}
                                </a>
                              ) : (
                                <Link to={itemSubMenu.link}>
                                  <i className={`fa fa-${itemMenu.icon}`}></i>
                                  <span className="nav-label">
                                    {itemSubMenu.label}
                                  </span>
                                </Link>
                              )}
                            </li>
                          ) : null
                        )}
                      </ul>
                    ) : null}
                  </li>
                ) : null
              )
            : null}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(SideNavBar);

const nutrients = [
  { id: "pd", name: "PD", min: "", max: "" },
  { id: "ed", name: "ED", min: "", max: "" },
  { id: "pDis", name: "P dis", min: "", max: "" },
  { id: "ca", name: "Ca", min: "", max: "" },
  { id: "naDis", name: "Na dis", min: "", max: "" },
  { id: "ciDis", name: "Cl dis", min: "", max: "" },
  { id: "kDis", name: "K dis", min: "", max: "" },
/*   { id: "arg", name: "Arg", min: "", max: "" },
  { id: "his", name: "His", min: "", max: "" },
  { id: "ile", name: "Ile", min: "", max: "" },
  { id: "leu", name: "Leu", min: "", max: "" },
  { id: "lys", name: "Lys", min: "", max: "" },
  { id: "met", name: "Met", min: "", max: "" },
  { id: "metCys", name: "Met+cys", min: "", max: "" },
  { id: "phe", name: "Phe", min: "", max: "" },
  { id: "pheThr", name: "Phe+Thr", min: "", max: "" },
  { id: "thr", name: "Thr", min: "", max: "" },
  { id: "trp", name: "Trp", min: "", max: "" },
  { id: "val", name: "Val", min: "", max: "" }, */
];

export default nutrients;

import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="pull-right">Poultry Model Web Portal &copy; {year}</div>
      <div>Programação dos softwares desenvolvida por Rafael – Zenbytes</div>
    </div>
  );
};

export default Footer;

import React from "react";
import utils from "../../../utils/functions";

const Results = (props) => {
  return (
    <div className="resultados mt-3 ">
      <table className="table ">
        <thead className="thead-dark ">
          <tr>
            <th className="col">Fase</th>
            <th className="col">Lys</th>
            <th className="col">Met+Cys</th>
            <th className="col">Thr</th>
            <th className="col">Trp</th>
            <th className="col">Arg</th>
            <th className="col">Val</th>
            <th className="col">Ile</th>
            <th className="col">Leu</th>
            <th className="col">His</th>
            <th className="col">Phe+Tyr</th>
            <th className="col">Gly+Ser</th>
          </tr>
          <tr>
            <th className="col">Semana</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
            <th className="col">%</th>
          </tr>
        </thead>
        <tbody>
          {props.result.map((item, index) => (
            <tr key={index}>
              <td>
                {item.start} a {item.end}
              </td>
              <td>{utils.filterMask("number", item.lys, 3)}</td>
              <td>{utils.filterMask("number", item.metCys, 3)}</td>
              <td>{utils.filterMask("number", item.thr, 3)}</td>
              <td>{utils.filterMask("number", item.trp, 3)}</td>
              <td>{utils.filterMask("number", item.arg, 3)}</td>
              <td>{utils.filterMask("number", item.val, 3)}</td>
              <td>{utils.filterMask("number", item.ile, 3)}</td>
              <td>{utils.filterMask("number", item.leu, 3)}</td>
              <td>{utils.filterMask("number", item.his, 3)}</td>
              <td>{utils.filterMask("number", item.pheTyr, 3)}</td>
              <td>{utils.filterMask("number", item.glySer, 3)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Results;

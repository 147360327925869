import React, { useState } from "react";
import "./index.css";
import ingredients from "../databases/ingredients";
import nutrients from "../databases/nutrients";
import Ingredients from "./Ingredients";
import Nutrients from "./Nutrients";
import ResultsIngredients from "./Results/Ingredients";
import ResultsNutrients from "./Results/Nutrients";
import solver from "javascript-lp-solver/src/solver";
import { useEffect } from "react";

const Formulador = (props) => {
  const sortByName = (a, b, key) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  };

  const [ingredientsSides, setIngredientsSides] = useState([
    ingredients.sort((a, b) => sortByName(a, b, "nome")),
    [],
  ]);

  const [ingredientsSearch, setIngredientsSearch] = useState(
    ingredientsSides[0]
  );

  const [inputSearchIngredients, setInputSearchIngredients] = useState("");

  const [tableIngredients, setTableIngredients] = useState([]);

  const [tableNutrients, setTableNutrients] = useState([]);

  const [nutrientsSides, setNutrientsSides] = useState([
    nutrients.sort((a, b) => sortByName(a, b, "nomept")),
    [],
  ]);

  const [nutrientsSearch, setNutrientsSearch] = useState(nutrientsSides[0]);

  const [inputSearchNutrients, setInputSearchNutrients] = useState("");

  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const [stateForm, setStateForm] = useState("");

  const [putdata, setPutData] = useState(true);

  const inputSelectedIngredientItemHandler = (value, key, index) => {
    const copyIngredientsSides = [...ingredientsSides];
    copyIngredientsSides[1][index][key] =
      value > 100 ? 100 : value < 0 ? 0 : value;
    setIngredientsSides(copyIngredientsSides);
  };

  const inputSelectedNutrientItemHandler = (value, key, index) => {
    const copyNutrientsSides = [...nutrientsSides];
    copyNutrientsSides[1][index][key] = value;
    setNutrientsSides(copyNutrientsSides);
  };

  const inputSearchIngredientsHandler = (value) => {
    setInputSearchIngredients(value);
    const copyIngredientsSearch = ingredientsSides[0].filter(
      (ingredient) =>
        removerAcentos(ingredient.nome.toLowerCase()).indexOf(
          removerAcentos(value.toLowerCase())
        ) > -1
    );
    setIngredientsSearch(copyIngredientsSearch);
  };

  const inputSearchNutrientsHandler = (value) => {
    setInputSearchNutrients(value);
    const copyNutrientsSearch = nutrientsSides[0].filter(
      (nutrient) =>
        removerAcentos(nutrient.nomept.toLowerCase()).indexOf(
          removerAcentos(value.toLowerCase())
        ) > -1
    );
    setNutrientsSearch(copyNutrientsSearch);
  };

  const removerAcentos = (newStringComAcento) => {
    var string = newStringComAcento;
    var mapaAcentosHex = {
      a: /[\xE0-\xE6]/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
      c: /\xE7/g,
      n: /\xF1/g,
    };

    for (var letra in mapaAcentosHex) {
      var expressaoRegular = mapaAcentosHex[letra];
      string = string.replace(expressaoRegular, letra);
    }

    return string;
  };

  const clearIngredientsSearch = () => {
    setIngredientsSearch(ingredientsSides[0]);
    setInputSearchIngredients("");
  };

  const removeIngredient = (index, e) => {
    if (e.target.className !== "form-control form-control-sm") {
      const copyIngredientsSides = [...ingredientsSides];
      copyIngredientsSides[1][index].min = "";
      copyIngredientsSides[1][index].max = "";
      copyIngredientsSides[1][index].userPrice = "";
      copyIngredientsSides[0].push(copyIngredientsSides[1][index]);
      copyIngredientsSides[0].sort((a, b) => sortByName(a, b, "nome"));
      copyIngredientsSides[1].splice(index, 1);
      setIngredientsSides(copyIngredientsSides);
      inputSearchIngredientsHandler(inputSearchIngredients);
    }
  };

  const selectIngredient = (ingredientName) => {
    const index = ingredientsSides[0]
      .map((ingredient) => ingredient.nome)
      .indexOf(ingredientName);
    const copyIngredientsSides = [...ingredientsSides];
    copyIngredientsSides[0][index].min = "";
    copyIngredientsSides[0][index].max = "";
    copyIngredientsSides[0][index].userPrice =
      copyIngredientsSides[0][index].preco;
    copyIngredientsSides[1].push(copyIngredientsSides[0][index]);
    copyIngredientsSides[1].sort((a, b) => sortByName(a, b, "nome"));
    console.log(index, "ingredient");
    copyIngredientsSides[0].splice(index, 1);
    setIngredientsSides(copyIngredientsSides);
    inputSearchIngredientsHandler(inputSearchIngredients);
  };

  const clearNutrientsSearch = () => {
    setNutrientsSearch(nutrientsSides[0]);
    setInputSearchNutrients("");
  };

  const removeNutrient = (index, e) => {
    if (e.target.className !== "form-control form-control-sm") {
      const copyNutrientsSides = [...nutrientsSides];
      copyNutrientsSides[1][index].min = "";
      copyNutrientsSides[1][index].max = "";
      copyNutrientsSides[0].push(copyNutrientsSides[1][index]);
      copyNutrientsSides[0].sort((a, b) => sortByName(a, b, "nomept"));
      copyNutrientsSides[1].splice(index, 1);
      setNutrientsSides(copyNutrientsSides);
      inputSearchNutrientsHandler(inputSearchNutrients);
    }
  };

  const selectNutrient = (nutrientName) => {
    const index = nutrientsSides[0]
      .map((nutrient) => nutrient.nome)
      .indexOf(nutrientName);
    const copyNutrientsSides = [...nutrientsSides];
    copyNutrientsSides[0][index].min = "";
    copyNutrientsSides[0][index].max = "";
    copyNutrientsSides[1].push(copyNutrientsSides[0][index]);
    copyNutrientsSides[1].sort((a, b) => sortByName(a, b, "pt"));
    console.log(index, "nutrient");
    copyNutrientsSides[0].splice(index, 1);
    setNutrientsSides(copyNutrientsSides);
    inputSearchNutrientsHandler(inputSearchNutrients);
  };

  const tabs = [
    {
      label: "Ingredientes",
      component: (
        <Ingredients
          inputSearchIngredients={inputSearchIngredients}
          clearIngredientsSearch={clearIngredientsSearch}
          removeIngredient={removeIngredient}
          selectIngredient={selectIngredient}
          ingredientsSearch={ingredientsSearch}
          ingredientsSides={ingredientsSides}
          inputSearchIngredientsHandler={inputSearchIngredientsHandler}
          inputSelectedIngredientItemHandler={
            inputSelectedIngredientItemHandler
          }
        />
      ),
    },
    {
      label: "Nutrientes",
      component: (
        <Nutrients
          inputSearchNutrients={inputSearchNutrients}
          clearNutrientsSearch={clearNutrientsSearch}
          removeNutrient={removeNutrient}
          selectNutrient={selectNutrient}
          nutrientsSearch={nutrientsSearch}
          nutrientsSides={nutrientsSides}
          inputSearchNutrientsHandler={inputSearchNutrientsHandler}
          inputSelectedNutrientItemHandler={inputSelectedNutrientItemHandler}
        />
      ),
    },
    {
      label: "Fórmula",
      component: <ResultsIngredients tableIngredients={tableIngredients} />,
      onlyIf: "formulado",
    },
    {
      label: "Composição Nutricional",
      component: <ResultsNutrients tableNutrients={tableNutrients} />,
      onlyIf: "formulado",
    },
  ];

  const formulate = (nutrientsSides) => {
    const constNutrients = {};
    nutrientsSides[1].forEach((nutrient) => {
      constNutrients[nutrient.nomeDB] = {
        min: nutrient.min ? +nutrient.min * ingredientsSides[1].length : 0,
      };
      if (nutrient.max) {
        constNutrients[nutrient.nomeDB].max =
          +nutrient.max * ingredientsSides[1].length;
      }
    });
    const constraints = {
      ...{
        ...ingredientsSides[1].map((item) => ({
          min: item.min ? (+item.min / 100) * ingredientsSides[1].length : 0,
          max: item.max
            ? (+item.max / 100) * ingredientsSides[1].length
            : ingredientsSides[1].length,
        })),
      },
      ...constNutrients,
      perc: { equal: ingredientsSides[1].length },
    };
    const model = {
      optimize: "userPrice",
      opType: "min",
      constraints,
      variables: {
        ...{
          ...ingredientsSides[1].map((ingredient, index) => ({
            ...ingredient,
            perc: 1,
            [index]: 1,
          })),
        },
      },
    };
    return solver.Solve(model);
  };

  useEffect(() => {
    if (putdata) {
      setPutData(false);
      const ingredients = [9, 13, 20, 25, 25, 25, 25, 28, 46, 65, 39];
      const nutrients = [9, 22, 5, 12, 20, 14];
      const nutrientsMin = [2800, 17, 3.8, 0.4, 0.16, 0.7];
      const nutrientsMax = [2800, "", 3.8, 0.4, 0.16, 0.7];
      const copyIngredientsSides = [...ingredientsSides];
      const copyNutrientsSides = [...nutrientsSides];
      ingredients.forEach((index) => {
        if (copyIngredientsSides[0][index]) {
          copyIngredientsSides[0][index].min = "";
          copyIngredientsSides[0][index].max = "";
          copyIngredientsSides[0][index].userPrice =
            copyIngredientsSides[0][index].preco;
          copyIngredientsSides[1].push(copyIngredientsSides[0][index]);
          copyIngredientsSides[1].sort((a, b) => sortByName(a, b, "nome"));
          copyIngredientsSides[0].splice(index, 1);
        }
      });

      nutrients.forEach((index, i) => {
        if (copyNutrientsSides[0][index]) {
          copyNutrientsSides[0][index].min = nutrientsMin[i];
          copyNutrientsSides[0][index].max = nutrientsMax[i];
          copyNutrientsSides[1].push(copyNutrientsSides[0][index]);
          copyNutrientsSides[1].sort((a, b) => sortByName(a, b, "nomept"));
          copyNutrientsSides[0].splice(index, 1);
        }
      });
      setNutrientsSides(copyNutrientsSides);
      setIngredientsSides(copyIngredientsSides);
    }
  }, [nutrientsSides, ingredientsSides, putdata]);
  return (
    <form
      className="formulador"
      onSubmit={async (event) => {
        event.preventDefault();
        const idealRelationship = {
          metCisDig: {
            value: 0.82,
            onlyMin: true,
            nome: "Met + Cis Digestível (%)",
          },
          treoninaDig: {
            value: 0.69,
            onlyMin: true,
            nome: "Treonina Digestível (%)",
          },
          triptofanoDig: {
            value: 0.21,
            onlyMin: true,
            nome: "Triptofano Digestível (%)",
          },
          valinaDig: {
            value: 0.91,
            onlyMin: true,
            nome: "Valina Digestível (%)",
          },
        };
        const feedResults = [];
        const copyNutrientsSides = [...nutrientsSides];

        const feedLysine = {
          min: 0,
          max: 0,
        };
        nutrientsSides[1].forEach((nutrient) => {
          if (nutrient.nomeDB === "lisinaDig") {
            feedLysine.min = +nutrient.min;
            feedLysine.max = +nutrient.max;
          }
        });
        Object.keys(idealRelationship).forEach((key) => {
          const nutrient = {
            min: feedLysine.min * idealRelationship[key].value,
            max: "",
            nomept: idealRelationship[key].nome,
            nomeDB: key,
          };
          copyNutrientsSides[1].push(nutrient);
        });
        const originalNutrients = [...copyNutrientsSides];
        const teste = [];
        for (let i = 0; i < 10; i++) {
          copyNutrientsSides[1] = nutrientsSides[1].map((nutrient) => {
            const copyNutrient = { ...nutrient };
            if (nutrient.nomeDB === "lisinaDig") {
              copyNutrient.min = feedLysine.min + i * 0.05;
              copyNutrient.max = copyNutrient.min;
            }
            if (idealRelationship[nutrient.nomeDB]) {
              copyNutrient.min =
                (feedLysine.min + 1 * 0.05) *
                idealRelationship[nutrient.nomeDB].value;
              if (!idealRelationship[nutrient.nomeDB].onlyMin) {
                copyNutrient.max = copyNutrient.min;
              }
            }
            return copyNutrient;
          });
          feedResults.push(formulate(copyNutrientsSides).result);
          teste.push(formulate(copyNutrientsSides));
        }
        let lysine = 0;
        let qtd = 1;
        feedResults.forEach((price, index) => {
          if (index > 0 && price > feedResults[index - 1]) {
            lysine += price - feedResults[index - 1];
            qtd++;
          }
        });
        lysine = (lysine / qtd) * 100;
        props.setLys(lysine);
        const { bounded, feasible, result, ...ingredietsResults } =
          formulate(originalNutrients);
        const copyTableIngredients = Object.keys(ingredietsResults).map(
          (key) => {
            const { nome, ...nutrients } = ingredientsSides[1][+key];
            return {
              nutrients,
              name: nome,
              percentage:
                (ingredietsResults[key] / ingredientsSides[1].length) * 100,
              price:
                ingredientsSides[1][+key].userPrice *
                (ingredietsResults[key] / ingredientsSides[1].length),
            };
          }
        );
        const nutrientsSum = {
          crudFiber: 0,
          fdn: 0,
          fda: 0,
          materiaSeca: 0,
          proteinaBrutaPB: 0,
          coefDigPBAves: 0,
          pBDigestivelAves: 0,
          energiaBruta: 0,
          energiaMetAves: 0,
          energiaMetVerdAves: 0,
          energiaEfetivaAves: 0,
          gordura: 0,
          coefDigGordura: 0,
          gorduraDigAves: 0,
          materiaOrganica: 0,
          materiaMineral: 0,
          lisinaTotal: 0,
          metioninaTotal: 0,
          metCisTotal: 0,
          treoninaTotal: 0,
          triptofanoTotal: 0,
          argininaTotal: 0,
          gliSerTotal: 0,
          valinaTotal: 0,
          isoleucinaTotal: 0,
          leucinaTotal: 0,
          histidinaTotal: 0,
          felaninaTotal: 0,
          fenTirTotal: 0,
          lisinaDig: 0,
          metioninaDig: 0,
          metCisDig: 0,
          treoninaDig: 0,
          triptofanoDig: 0,
          argininaDig: 0,
          gliSerDig: 0,
          valinaDig: 0,
          isoleucinaDig: 0,
          leucinaDig: 0,
          histidinaDig: 0,
          felaninaDig: 0,
          fenTirDig: 0,
          potassio: 0,
          sodio: 0,
          cloro: 0,
          balancoEletrolico: 0,
          pTotal: 0,
          pDisp: 0,
          ca: 0,
        };
        copyTableIngredients.forEach((ingredient) => {
          Object.keys(nutrientsSum).forEach((key) => {
            if (ingredient.nutrients[key]) {
              nutrientsSum[key] +=
                ingredient.nutrients[key] * (ingredient.percentage / 100);
            }
          });
        });
        const copyTableNutrients = nutrientsSides[1]
          .map((nutrient) => ({
            name: nutrient.nomept,
            value: nutrientsSum[nutrient.nomeDB],
          }))
          .sort((item) => !!item.value);
        setTableNutrients(copyTableNutrients);
        setTableIngredients(copyTableIngredients);
        setStateForm("formulado");
        setTabActiveIndex(2);
      }}
    >
      <div className="row">
        <div className={`col-sm-${stateForm ? "9" : "6"} tabs-container`}>
          <ul className="nav nav-tabs formulador__tabs">
            {tabs
              .filter((tab) => tab.onlyIf === stateForm || !tab.onlyIf)
              .map((tab, index) => (
                <li
                  className="nav-item"
                  key={`tab${removerAcentos(tab.label) + index}`}
                >
                  <span
                    className={`nav-link pointer ${
                      index === tabActiveIndex ? "active" : ""
                    }`}
                    onClick={() => setTabActiveIndex(index)}
                  >
                    {tab.label}
                  </span>
                </li>
              ))}
          </ul>
        </div>
        <div className={`col-sm-${stateForm ? "3" : "6"} formulador__submit`}>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!ingredientsSides[1][0]}
          >
            Formular Ração
          </button>
        </div>
      </div>
      {tabs[tabActiveIndex].component}
    </form>
  );
};

export default Formulador;

import React from "react";
import { Link, withRouter } from "react-router-dom";

const HeaderContent = props => {  
  return (
    <div className="row wrapper border-bottom white-bg page-heading">
      <div className="col-lg-6">
        <h2>{props.label} {props.small ? <><br /><small style={{fontSize:'55%'}}>{props.small} </small></> : null}</h2>
       
        <ol className="breadcrumb">
          {props.links.map((item, index) => (
            <li className={item.active ? "active" : ""} key={item.label+index}>
              {item.link ? (
                <Link to={item.link}>
                  {item.active ? <strong>{item.label}</strong> : item.label}
                </Link>
              ) : item.active ? (
                <strong>{item.label}</strong>
              ) : (
                item.label
              )}
            </li>
          ))}
        </ol>
      </div>
      <div className="col-lg-6 content__back">
        <button type="button" className="btn btn-default btn-voltar" onClick={props.history.goBack}>
          <i className="fa fa-arrow-left"></i> Voltar
        </button>
      </div>
    </div>
  );
};

export default withRouter(HeaderContent);

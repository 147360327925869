import React, { Fragment, useState } from "react";
import "./index.css";
import FormInputs from "./FormInputs";
import Result from "./Result";
import PolynomialRegression from "js-polynomial-regression";
import HeaderContent from "../../components/HeaderContent";

const Broiler = (props) => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const initialInput = {
    dayData: {
      day: {
        value: "",
        label: "Dia",
      },
      temperature: {
        value: "",
        label: "Temperatura",
      },
      me: {
        value: "",
        label: "Energia Metabolizável",
      },
      bodyWeight: {
        value: "",
        label: "Peso Vivo",
      },
      feedIntake: {
        value: "",
        label: "Consumo",
      },
    },
    feedProgram: {
      start: {
        value: "",
        label: "Início",
      },
      end: {
        value: "",
        label: "Fim",
      },
    },
  };

  const initialLineage = {
    rossMale: {
      bodyWeightWm: 8.3755,
      bodyWeightB: 0.04,
      bodyWeightWi: 0.0437,
      bodyProteinWm: 1.2656,
      bodyProteinB: 0.039,
      bodyProteinWi: 0.0055,
      featherProteinWm: 0.2171,
      featherProteinB: 0.039,
      featherProteinWi: 0.00158,
      bodyFatA: -1.247,
      bodyFatB: 1.157,
    },
    cobbMale: {
      bodyWeightWm: 8.1107,
      bodyWeightB: 0.042,
      bodyWeightWi: 0.044,
      bodyProteinWm: 1.296,
      bodyProteinB: 0.043,
      bodyProteinWi: 0.006,
      featherProteinWm: 0.1897,
      featherProteinB: 0.045,
      featherProteinWi: 0.00137,
      bodyFatA: -1.225,
      bodyFatB: 1.157,
    },
    hubbardMale: {
      bodyWeightWm: 9.1863,
      bodyWeightB: 0.037,
      bodyWeightWi: 0.0406,
      bodyProteinWm: 1.477,
      bodyProteinB: 0.036,
      bodyProteinWi: 0.0052,
      featherProteinWm: 0.1773,
      featherProteinB: 0.042,
      featherProteinWi: 0.00137,
      bodyFatA: -1.357,
      bodyFatB: 1.157,
    },
    rossFemale: {
      bodyWeightWm: 6.754,
      bodyWeightB: 0.04,
      bodyWeightWi: 0.0419,
      bodyProteinWm: 0.9005,
      bodyProteinB: 0.041,
      bodyProteinWi: 0.006,
      featherProteinWm: 0.1804,
      featherProteinB: 0.042,
      featherProteinWi: 0.00157,
      bodyFatA: -1.3727,
      bodyFatB: 1.1969,
    },
    cobbFemale: {
      bodyWeightWm: 6.565,
      bodyWeightB: 0.042,
      bodyWeightWi: 0.0453,
      bodyProteinWm: 0.9099,
      bodyProteinB: 0.043,
      bodyProteinWi: 0.006,
      featherProteinWm: 0.1752,
      featherProteinB: 0.047,
      featherProteinWi: 0.00156,
      bodyFatA: -1.681,
      bodyFatB: 1.2468,
    },
    hubbardFemale: {
      bodyWeightWm: 6.874,
      bodyWeightB: 0.039,
      bodyWeightWi: 0.0379,
      bodyProteinWm: 0.949,
      bodyProteinB: 0.04,
      bodyProteinWi: 0.005,
      featherProteinWm: 0.17,
      featherProteinB: 0.05,
      featherProteinWi: 0.00149,
      bodyFatA: -1.5537,
      bodyFatB: 1.221,
    },
  };

  const [inputs, setInputs] = useState({
    //weekData: [{ ...initialInput.weekData }],
    //feedProgram: [{ ...initialInput.feedProgram }],
    feedProgram: [
      { start: { value: 1 }, end: { value: 7 } },
      { start: { value: 8 }, end: { value: 21 } },
      { start: { value: 22 }, end: { value: 35 } },
      { start: { value: 36 }, end: { value: 42 } },
    ],
    dayData: [
      {
        day: { value: 7 },
        temperature: { value: 32 },
        me: { value: 3.1 },
        bodyWeight: { value: 0.16 },
        feedIntake: { value: 35 },
      },
      {
        day: { value: 14 },
        temperature: { value: 29 },
        me: { value: 3.1 },
        bodyWeight: { value: 0.43 },
        feedIntake: { value: 70 },
      },
      {
        day: { value: 21 },
        temperature: { value: 26 },
        me: { value: 3.15 },
        bodyWeight: { value: 0.895 },
        feedIntake: { value: 119 },
      },
      {
        day: { value: 28 },
        temperature: { value: 23 },
        me: { value: 3.15 },
        bodyWeight: { value: 1.55 },
        feedIntake: { value: 160 },
      },
      {
        day: { value: 35 },
        temperature: { value: 20 },
        me: { value: 3.2 },
        bodyWeight: { value: 2.33 },
        feedIntake: { value: 209 },
      },
      {
        day: { value: 42 },
        temperature: { value: 20 },
        me: { value: 3.2 },
        bodyWeight: { value: 3.1 },
        feedIntake: { value: 233 },
      },
      {
        day: { value: 49 },
        temperature: { value: 20 },
        me: { value: 3.2 },
        bodyWeight: { value: 3.89 },
        feedIntake: { value: 270 },
      },
      {
        day: { value: 56 },
        temperature: { value: 20 },
        me: { value: 3.25 },
        bodyWeight: { value: 4.6 },
        feedIntake: { value: 265 },
      },
    ],
    lineage: "cobbMale",
    useFi: false,
    useBw: false,
  });

  const [lineageData, setLineageData] = useState(initialLineage);

  const [result, setResult] = useState([]);

  const addLineDay = () => {
    const copyInputs = { ...inputs };
    copyInputs.dayData.push({ ...initialInput.dayData });
    if (copyInputs.dayData[copyInputs.dayData.length - 2].day.value) {
      copyInputs.dayData[copyInputs.dayData.length - 1].day.value =
        +copyInputs.dayData[copyInputs.dayData.length - 2].day.value + 1;
    }
    setInputs(copyInputs);
  };

  const addLineFeedProgram = () => {
    const copyInputs = { ...inputs };
    copyInputs.feedProgram.push({ ...initialInput.feedProgram });
    if (copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value) {
      copyInputs.feedProgram[copyInputs.feedProgram.length - 1].start.value =
        +copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value +
        1;
    }
    setInputs(copyInputs);
  };

  const removeLine = (key) => {
    const copyInputs = { ...inputs };
    copyInputs[key].pop();
    setInputs(copyInputs);
  };

  const checkboxHandler = (checked, key) => {
    const copyInputs = { ...inputs };
    copyInputs[key] = checked;
    setInputs(copyInputs);
  };

  const inputHandler = (index, key, event, keyList) => {
    const copyInputs = { ...inputs };
    if (index >= 0) {
      copyInputs[keyList] = [...inputs[keyList]];
      copyInputs[keyList][index][key].value =
        +event.target.value || event.target.value === "0"
          ? +event.target.value
          : "";
    } else {
      copyInputs[key] = event.target.value;
    }
    setInputs(copyInputs);
  };

  const calcSQ = (lastDayOfWeek) => {
    const alometry = {
      a: {
        bwXpw: -2,
        pwXfw: -1.247,
      },
      b: {
        bwXpw: 1.03,
        pwXfw: 1.157,
      },
    };
    const dif = lastDayOfWeek.map((item, index) => {
      const bw = inputs.dayData[index].bodyWeight.value;
      const response = {
        pv: Math.pow(item.pv - bw, 2),
        ppc: Math.pow(
          2.71828,
          alometry.a.bwXpw + Math.log(bw) * alometry.b.bwXpw
        ),
        week: item.week,
      };
      response.ppp = Math.pow(item.ppp - response.ppc * 0.28, 2);
      response.ppc = Math.pow(item.ppc - response.ppc, 2);
      return response;
    });
    return {
      bw: dif.map((item) => item.pv).reduce((a, b) => a + b),
      pw: dif.map((item) => item.ppc).reduce((a, b) => a + b),
      fw: dif.map((item) => item.ppp).reduce((a, b) => a + b),
    };
  };

  const getResponseReport = (lineage) => {
    const response = {
      days: [],
      output: [],
      lastDayOfWeek: [],
    };
    const ltc = [31, 28, 24, 22, 20, 18];
    let indexDay = 1;
    let indexFeed = 0;
    const output = {
      me: 0,
      fi: 0,
      Lys: 0,
      MetCys: 0,
      Thr: 0,
      Val: 0,
      Iso: 0,
      Trp: 0,
    };
    const dataRegression = inputs.dayData.map((item) => ({
      x: item.day.value,
      y: item.feedIntake.value,
    }));
    const model = PolynomialRegression.read(
      dataRegression,
      inputs.dayData.length < 4 ? 1 : 3
    );
    const terms = model.getTerms();

    inputs.dayData.forEach((item, index) => {
      const ltcWeek = ltc[index] ? ltc[index] : 18;
      for (let i = indexDay; i <= item.day.value; i++) {
        const dayData = {
          day: indexDay,
        };
        dayData.bw =
          lineage.bodyWeightWm *
          Math.exp(
            -Math.exp(
              Math.log(-Math.log(lineage.bodyWeightWi / lineage.bodyWeightWm)) -
                lineage.bodyWeightB * indexDay
            )
          );
        dayData.bwg =
          lineage.bodyWeightB *
          dayData.bw *
          Math.log(lineage.bodyWeightWm / dayData.bw);
        dayData.prot =
          lineage.bodyProteinWm *
          Math.exp(
            -Math.exp(
              Math.log(
                -Math.log(lineage.bodyProteinWi / lineage.bodyProteinWm)
              ) -
                lineage.bodyProteinB * indexDay
            )
          );
        dayData.bpd =
          lineage.bodyProteinB *
          dayData.prot *
          Math.log(lineage.bodyProteinWm / dayData.prot) *
          1000;
        dayData.fpw =
          lineage.featherProteinWm *
          Math.exp(
            -Math.exp(
              Math.log(
                -Math.log(lineage.featherProteinWi / lineage.featherProteinWm)
              ) -
                lineage.featherProteinB * indexDay
            )
          );
        dayData.fpd =
          lineage.featherProteinB *
          dayData.fpw *
          Math.log(lineage.featherProteinWm / dayData.fpw) *
          1000;
        dayData.fat = Math.pow(
          2.71828,
          lineage.bodyFatA + Math.log(dayData.prot * 1000) * lineage.bodyFatB
        );
        dayData.fg =
          indexDay === 1
            ? dayData.fat * 0.18
            : dayData.fat - response.days[indexDay - 2].fat;
        dayData.me =
          item.temperature.value > ltcWeek
            ? Math.pow(dayData.bw, 0.75) * 113 +
              0.88 * (item.temperature.value - ltcWeek) +
              13.5 * dayData.fg +
              12.3 * dayData.bpd
            : Math.pow(dayData.bw, 0.75) * 113 +
              6.73 * (ltcWeek - item.temperature.value) +
              13.5 * dayData.fg +
              12.3 * dayData.bpd;
        dayData.fi = !inputs.useFi
          ? dayData.me / item.me.value
          : model.predictY(terms, indexDay);
        dayData.lys = 0.7478 + 0.129 * (1 - Math.exp(0.1334 * dayData.bw));
        dayData.metCys = 0.75 + 0.156 * (1 - Math.exp(0.1455 * dayData.bw));
        dayData.thr = 0.7811 + 0.111 * (1 - Math.exp(0.1478 * dayData.bw));
        dayData.val = 0.7112 + 0.08 * (1 - Math.exp(0.1055 * dayData.bw));
        dayData.iso = 0.7792 - 0.1411 * (1 - Math.exp(-1.8968 * dayData.bw));
        dayData.trp = 0.7056 + 0.1929 * (1 - Math.exp(0.00877 * dayData.bw));
        dayData.mmLys =
          (75 * dayData.bpd + 18 * dayData.fpd) / dayData.lys +
          (Math.pow(lineage.bodyProteinWm, 0.73) *
            (dayData.prot / lineage.bodyProteinWm) *
            151.2 +
            0.01 * dayData.fpw * 18);
        dayData.mmMetCys =
          (36 * dayData.bpd + 89 * dayData.fpd) / dayData.metCys +
          Math.pow(lineage.bodyProteinWm, 0.73) *
            (dayData.prot / lineage.bodyProteinWm) *
            87.2 +
          0.01 * dayData.fpw * 89;
        dayData.mmThr =
          (42 * dayData.bpd + 44 * dayData.fpd) / dayData.thr +
          Math.pow(lineage.bodyProteinWm, 0.73) *
            (dayData.prot / lineage.bodyProteinWm) *
            75.5 +
          0.01 * dayData.fpw * 44;
        dayData.mmVal =
          (45 * dayData.bpd) / dayData.val +
          (69 * dayData.fpd) / dayData.val +
          Math.pow(lineage.bodyProteinWm, 0.73) *
            (dayData.prot / lineage.bodyProteinWm) *
            219 +
          0.01 * dayData.fpw * 69;
        dayData.mmIso =
          (40 * dayData.bpd) / dayData.iso +
          (47 * dayData.fpd) / dayData.iso +
          Math.pow(lineage.bodyProteinWm, 0.73) *
            (dayData.prot / lineage.bodyProteinWm) *
            134 +
          0.01 * dayData.fpw * 47;
        dayData.mmTrp =
          (11 * dayData.bpd) / dayData.trp +
          (7 * dayData.fpd) / dayData.trp +
          Math.pow(lineage.bodyProteinWm, 0.73) *
            (dayData.prot / lineage.bodyProteinWm) *
            37 +
          0.01 * dayData.fpw * 7;
        dayData.emLys =
          45.1 * Math.pow(dayData.bw, 0.75) +
          (-23.14 + 13.39 * (dayData.bwg * 1000)) / dayData.lys;
        dayData.emMetCys =
          25.5 * Math.pow(dayData.bw, 0.75) +
          (-28.342 + 9.68 * (dayData.bwg * 1000)) / dayData.metCys;
        dayData.emThr =
          (21, 9 * Math.pow(dayData.bw, 0.75)) +
          (-11.15 + 9.13 * (dayData.bwg * 1000)) / dayData.thr;
        dayData.emVal =
          (65, 4 * Math.pow(dayData.bw, 0.75)) +
          (-22.03 + 9.54 * (dayData.bwg * 1000)) / dayData.val;
        dayData.emIso =
          32 * Math.pow(dayData.bw, 0.75) +
          (-13.792 + 7.3444 * (dayData.bwg * 1000)) / dayData.iso;
        dayData.emTrp =
          9 * Math.pow(dayData.bw, 0.75) +
          (-2.94 + 2.38 * (dayData.bwg * 1000)) / dayData.trp;
        Object.keys(output).forEach((key) => {
          output[key] +=
            key !== "me" && key !== "fi" ? dayData["mm" + key] : dayData[key];
        });
        if (
          inputs.feedProgram[indexFeed] &&
          +inputs.feedProgram[indexFeed].end.value === indexDay
        ) {
          for (let key in output) {
            output[key] =
              output[key] /
              (inputs.feedProgram[indexFeed].end.value -
                inputs.feedProgram[indexFeed].start.value +
                1);
          }
          Object.keys(output).forEach((key) => {
            output[key + "P"] = (output[key] / (output.fi * 1000)) * 100;
          });
          response.output.push({ ...output });
          Object.keys(output).forEach((key) => {
            output[key] = 0;
          });
          indexFeed++;
        }
        response.days.push({ ...dayData });
        indexDay++;
      }
      response.lastDayOfWeek.push({
        pv: response.days[response.days.length - 1].bw,
        ppc: response.days[response.days.length - 1].prot,
        ppp: response.days[response.days.length - 1].fpw,
        day: response.days[response.days.length - 1].day,
      });
    });

    return response;
  };

  const generateReport = () => {
    let lineage = { ...initialLineage[inputs.lineage] };
    let response = getResponseReport(lineage);
    if (inputs.useBw) {
      const variation = {
        bodyWeightWm: { min: 9.0, max: 6.0 },
        bodyWeightB: { min: 0.03, max: 0.055 },
        bodyProteinWm: { min: 0.8, max: 1.4 },
        bodyProteinB: { min: 0.03, max: 0.055 },
        featherProteinWm: { min: 0.15, max: 0.3 },
        featherProteinB: { min: 0.03, max: 0.055 },
      };
      let bestSQ = calcSQ(response.lastDayOfWeek);
      let indexLineage = 0;
      const arrayLineage = [];
      for (let i = 0; i < 10; i++) {
        arrayLineage.push({ ...lineage });
        arrayLineage[i].bodyWeightWm =
          variation.bodyWeightWm.min +
          ((variation.bodyWeightWm.max - variation.bodyWeightWm.min) / 10) * i;
        arrayLineage[i].bodyWeightB =
          variation.bodyWeightB.min +
          ((variation.bodyWeightB.max - variation.bodyWeightB.min) / 10) * i;
        response = getResponseReport(arrayLineage[i]);
        const sq = calcSQ(response.lastDayOfWeek);
        if (sq.bw < bestSQ.bw) {
          bestSQ = { ...sq };
          indexLineage = i;
        }
      }
      lineage = { ...arrayLineage[indexLineage] };
      for (let i = 0; i < 10; i++) {
        arrayLineage[i] = { ...lineage };
        arrayLineage[i].bodyProteinWm =
          variation.bodyProteinWm.min +
          ((variation.bodyProteinWm.max - variation.bodyProteinWm.min) / 10) *
            i;
        arrayLineage[i].bodyProteinB =
          variation.bodyProteinB.min +
          ((variation.bodyProteinB.max - variation.bodyProteinB.min) / 10) * i;
        response = getResponseReport(arrayLineage[i]);
        const sq = calcSQ(response.lastDayOfWeek);
        if (sq.pw < bestSQ.pw) {
          bestSQ = { ...sq };
          indexLineage = i;
        }
      }
      lineage = { ...arrayLineage[indexLineage] };
      for (let i = 0; i < 10; i++) {
        arrayLineage[i] = { ...lineage };
        arrayLineage[i].featherProteinWm =
          variation.featherProteinWm.min +
          ((variation.featherProteinWm.max - variation.featherProteinWm.min) /
            10) *
            i;
        arrayLineage[i].featherProteinB =
          variation.featherProteinB.min +
          ((variation.featherProteinB.max - variation.featherProteinB.min) /
            10) *
            i;
        response = getResponseReport(arrayLineage[i]);
        const sq = calcSQ(response.lastDayOfWeek);
        if (sq.fw < bestSQ.fw) {
          bestSQ = { ...sq };
          indexLineage = i;
        }
      }
      response = getResponseReport(arrayLineage[indexLineage]);
      const copyLineageData = { ...lineageData };
      copyLineageData[inputs.lineage] = arrayLineage[indexLineage];
      setLineageData(copyLineageData);
    }
    console.log(response);
    setResult(
      response.output.map((item, index) => {
        item.start = inputs.feedProgram[index].start.value;
        item.end = inputs.feedProgram[index].end.value;
        return item;
      })
    );
    setTabActiveIndex(1);
  };

  const tabs = [
    {
      label: "Entrada de Dados",
      component: (
        <FormInputs
          inputs={inputs}
          addLineDay={addLineDay}
          addLineFeedProgram={addLineFeedProgram}
          removeLine={removeLine}
          inputHandler={inputHandler}
          generateReport={generateReport}
          lineageData={lineageData}
          checkboxHandler={checkboxHandler}
        />
      ),
    },
    {
      label: "Resultado (ave/dia)",
      component: <Result result={result} />,
    },
  ];

  return (
    <Fragment>
      <HeaderContent
        label="Programa Nutricional para Frango de Corte"
        links={[
          { label: "home", link: "/home" },
          { label: "Programa Nutricional para Frango de Corte", active: true },
        ]}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-md-12 text-left">
            <div className="ibox float-e-margins">
              <div className="ibox-content icons-box">
                <div className="row">
                  <div className="col-md-12 tabs-container">
                    <ul className="nav nav-tabs">
                      {tabs.map((tab, index) => (
                        <li key={`tab${index}`}>
                          <span
                            className={`nav-link pointer ${
                              index === tabActiveIndex ? "active" : ""
                            }`}
                            onClick={() => setTabActiveIndex(index)}
                          >
                            {tab.label}
                          </span>
                        </li>
                      ))}
                    </ul>
                    {tabs.map((tab, index) => (
                      <div
                        key={`tab${index}`}
                        style={{
                          display: index === tabActiveIndex ? "block" : "none",
                          marginTop: "10px",
                          padding: "10px",
                        }}
                      >
                        {tab.component}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Broiler;

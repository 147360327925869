import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import Status from "../../../components/Status";
import Checkbox from "../../../components/Checkbox";
import HeaderContent from "../../../components/HeaderContent";
import ListFilter from "../../../components/ListFilter";
import StatusFilter from "../../../components/StatusFilter";
import api from "../../../services/api";
import DropdownContext from "../../../contexts/dropdownContext";
import ReactLoading from "react-loading";
import Pagination from "react-js-pagination";
import { logout } from "../../../services/auth";
import Swal from "sweetalert2";

const ListUser = props => {
  const dropdownContext = useContext(DropdownContext);

  const [runLoadItems, setRunLoadItems] = useState(true);

  const [users, setUsers] = useState([]);

  const [totalUsers, setTotalUsers] = useState(0);

  const [loading, setLoading] = useState(true);

  const [loadingRemove, setLoadingRemove] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const [currentPath, setCurrentPath] = useState("");

  const selectAllHandler = checked => {
    const copyUsers = users.map(item => {
      item.checked = checked;
      return item;
    });
    setUsers(copyUsers);
    setSelectAll(checked);
  };

  const selectItemsHandler = (checked, index) => {
    const copyUsers = [...users];
    copyUsers[index].checked = checked;
    setUsers(copyUsers);
    const hasNotChecked = copyUsers.filter(item => {
      return !item.checked;
    });
    setSelectAll(hasNotChecked.length === 0);
  };

  const pageClickHandler = pageNumber => {
    if (+pageNumber !== +props.match.params.page) {
      props.history.push(
        props.match.path
          .replace(":page", pageNumber)
          .replace(":limit", props.match.params.limit)
          .replace(":sort", props.match.params.sort)
          .replace(":query", props.match.params.query)
      );
      setRunLoadItems(true);
      setLoading(true);
    }
  };

  const removeItemsHandler = async () => {
    const selectItemsIds = users
      .filter(user => user.checked)
      .map(item => item._id);
    if (selectItemsIds[0]) {
      const result = await Swal.fire({
        title: `Deseja deletar o${selectItemsIds[1] ? "s" : ""} usuário${
          selectItemsIds[1] ? "s" : ""
        } selecionados?`,
        text: "Está ação não poderá ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Deletar Usuários!"
      });
      if (result.value) {
        setLoadingRemove(true);
        const response = await api.delete("/user", { data: selectItemsIds });
        if (response.data) {
          setRunLoadItems(true);
        }
        setLoadingRemove(false);

        Swal.fire(
          `Deletar Usuário${selectItemsIds[1] ? "s" : ""}!`,
          `Usuário${selectItemsIds[1] ? "s" : ""} deletado${
            selectItemsIds[1] ? "s" : ""
          } com sucesso.`,
          "success"
        );
      }
    }
  };

  const clearFilter = () => {
    props.history.push(
      props.match.path
        .replace(":page", 1)
        .replace(":limit", props.match.params.limit)
        .replace(":sort", props.match.params.sort)
        .replace(":query", encodeURI("{}"))
    );
  };

  useEffect(() => {
    const loadItems = async () => {
      const response = await api
        .get("user", {
          params: { ...props.match.params }
        })
        .catch(err => err);
      if (!response.data) {
        if (response.message === "Request failed with status code 401") {
          return logout();
        }
      }
      setSelectAll(false);
      setUsers(response.data.docs);
      setTotalUsers(response.data.total);
      setLoading(false);
    };
    if (runLoadItems || props.match.url !== currentPath) {
      setCurrentPath(props.match.url);
      setRunLoadItems(false);
      loadItems();
    }
  }, [props.match, runLoadItems, currentPath]);
  return (
    <Fragment>
      <HeaderContent
        label="Lista de Usuários"
        previewPage={props.history.goBack}
        links={[
          { label: "home", link: "/home" },
          { label: "Adminstração" },
          { label: "Usuários", active: true }
        ]}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        {loading ? (
          <Loading type="spin" color="#0451e8" height={30} width={30} />
        ) : (
          <div className="row">
            <div className="col-md-12 text-left">
              <div className="ibox float-e-margins">
                <div
                  className={`ibox-title opcoes ${
                    dropdownContext.showDropdown.options ? "open" : ""
                  }`}
                >
                  <small>
                    {totalUsers} Usuário{totalUsers > 1 ? "s" : ""} encontrado
                    {totalUsers > 1 ? "s" : ""}&nbsp;
                    {JSON.parse(props.match.params.query)._id ? (
                      <button
                        className="btn btn-warning btn-sm"
                        onClick={clearFilter}
                      >
                        Limpar Filtro
                      </button>
                    ) : null}
                  </small>
                  <div>
                    <Link
                      to="/administracao/usuario"
                      className="btn btn-primary btn-sm opcoes__add"
                    >
                      <i className="fa fa-user-plus" /> Novo Usuário
                    </Link>
                    <button
                      className="btn btn-warning btn-sm"
                      onClick={() => {
                        dropdownContext.dropdownHandler("options");
                      }}
                    >
                      {loadingRemove ? (
                        <div className="opcoes__loading">
                          <ReactLoading
                            type="bubbles"
                            color="#fff"
                            height={15}
                            width={15}
                          />
                        </div>
                      ) : (
                        <span>
                          Opções <i className="fa fa-angle-down" />
                        </span>
                      )}
                    </button>
                  </div>

                  <ul className="dropdown-menu opcoes__menu animated fadeInRight m-t-xs">
                    <li>
                      <div
                        className={`dropdown-menu_link ${
                          !users.filter(item => !!item.checked)[0]
                            ? "inactive"
                            : ""
                        }`}
                        onClick={removeItemsHandler}
                      >
                        Remover Usuários Selecionados
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ibox-content icons-box">
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <div className="text-center">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>
                                <Checkbox
                                  checked={selectAll}
                                  onChange={selectAllHandler}
                                />
                              </th>
                              <th>Index</th>
                              <ListFilter
                                label="Nome"
                                match={props.match}
                                param="name"
                                model="user"
                                pushRoute={props.history.push}
                              />
                              <ListFilter
                                label="Cargo"
                                match={props.match}
                                param="responsibility"
                                model="user"
                                pushRoute={props.history.push}
                              />
                              <ListFilter
                                label="E-mail"
                                match={props.match}
                                param="username"
                                model="user"
                                pushRoute={props.history.push}
                              />
                              <StatusFilter
                                label="Status"
                                match={props.match}
                                param="status"
                                model="user"
                                pushRoute={props.history.push}
                              />
                              <th>Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map((user, index) => (
                              <tr key={user._id}>
                                <th>
                                  <Checkbox
                                    checked={users[index].checked}
                                    onChange={e => selectItemsHandler(e, index)}
                                  />
                                </th>
                                <td>{index + 1}</td>
                                <td>{user.name}</td>
                                <td>{user.responsibility}</td>
                                <td>{user.username}</td>
                                <td>
                                  <Status status={user.status} />
                                </td>
                                <td>
                                  <Link
                                    className="btn btn-primary"
                                    to={`/administracao/usuario/${user._id}`}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="7">
                                <Pagination
                                  activePage={+props.match.params.page}
                                  itemsCountPerPage={+props.match.params.limit}
                                  totalItemsCount={+totalUsers}
                                  pageRangeDisplayed={10}
                                  onChange={pageClickHandler}
                                  innerClass="pagination is-centered is-small"
                                  itemClass="pagination-link"
                                  activeClass="is-current"
                                  disabledClass="is-disabled"
                                  prevPageText={
                                    <i className="fa fa-angle-left" />
                                  }
                                  firstPageText={
                                    <i className="fa fa-angle-double-left" />
                                  }
                                  lastPageText={
                                    <i className="fa fa-angle-double-right" />
                                  }
                                  nextPageText={
                                    <i className="fa fa-angle-right" />
                                  }
                                />
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(ListUser);

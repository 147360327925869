import React, { useState, useEffect } from "react";
import "./index.css";
import nutrients from "../databases/nutrients";
import Ingredients from "./Ingredients";
import Nutrients from "./Nutrients";
import Results from "./Results";
import solver from "javascript-lp-solver/src/solver";
import api from "../../../services/api";
import { logout } from "../../../services/auth";

const Formulador = props => {
  const sortByName = (a, b, key) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  };

  const [ingredientsSides, setIngredientsSides] = useState([[], []]);

  const [ingredientsSearch, setIngredientsSearch] = useState(
    ingredientsSides[0]
  );

  const [inputSearchIngredients, setInputSearchIngredients] = useState("");

  const [tableIngredients, setTableIngredients] = useState([]);

  const [nutrientsSides, setNutrientsSides] = useState([
    nutrients.sort((a, b) => sortByName(a, b, "name")),
    []
  ]);

  const [nutrientsSearch, setNutrientsSearch] = useState(nutrientsSides[0]);

  const [inputSearchNutrients, setInputSearchNutrients] = useState("");

  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const [stateForm, setStateForm] = useState("");

  const [getIngredients, setGetIngredients] = useState(true);

  const inputSelectedIngredientItemHandler = (value, key, index) => {
    const copyIngredientsSides = [...ingredientsSides];
    copyIngredientsSides[1][index][key] =
      value > 100 ? 100 : value < 0 ? 0 : value;
    setIngredientsSides(copyIngredientsSides);
  };

  const inputSelectedNutrientItemHandler = (value, key, index) => {
    const copyNutrientsSides = [...nutrientsSides];
    copyNutrientsSides[1][index][key] = value;
    setNutrientsSides(copyNutrientsSides);
  };

  const inputSearchIngredientsHandler = value => {
    setInputSearchIngredients(value);
    const copyIngredientsSearch = ingredientsSides[0].filter(
      ingredient =>
        removerAcentos(ingredient.name.toLowerCase()).indexOf(
          removerAcentos(value.toLowerCase())
        ) > -1
    );
    setIngredientsSearch(copyIngredientsSearch);
  };

  const inputSearchNutrientsHandler = value => {
    setInputSearchNutrients(value);
    const copyNutrientsSearch = nutrientsSides[0].filter(
      nutrient =>
        removerAcentos(nutrient.name.toLowerCase()).indexOf(
          removerAcentos(value.toLowerCase())
        ) > -1
    );
    setNutrientsSearch(copyNutrientsSearch);
  };

  const removerAcentos = newStringComAcento => {
    var string = newStringComAcento;
    var mapaAcentosHex = {
      a: /[\xE0-\xE6]/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
      c: /\xE7/g,
      n: /\xF1/g
    };

    for (var letra in mapaAcentosHex) {
      var expressaoRegular = mapaAcentosHex[letra];
      string = string.replace(expressaoRegular, letra);
    }

    return string;
  };

  const clearIngredientsSearch = () => {
    setIngredientsSearch(ingredientsSides[0]);
    setInputSearchIngredients("");
  };

  const removeIngredient = (index, e) => {
    if (e.target.className !== "form-control form-control-sm") {
      const copyIngredientsSides = [...ingredientsSides];
      copyIngredientsSides[1][index].min = "";
      copyIngredientsSides[1][index].max = "";      
      copyIngredientsSides[0].push(copyIngredientsSides[1][index]);
      copyIngredientsSides[0].sort((a, b) => sortByName(a, b, "name"));
      copyIngredientsSides[1].splice(index, 1);
      setIngredientsSides(copyIngredientsSides);
      inputSearchIngredientsHandler(inputSearchIngredients);
    }
  };

  const selectIngredient = ingredientName => {
    const index = ingredientsSides[0]
      .map(ingredient => ingredient.name)
      .indexOf(ingredientName);
    const copyIngredientsSides = [...ingredientsSides];
    copyIngredientsSides[0][index].min = "";
    copyIngredientsSides[0][index].max = "";
    copyIngredientsSides[0][index].price = copyIngredientsSides[0][index].price ?
      parseFloat(copyIngredientsSides[0][index].price).toFixed(2) : 0;
    copyIngredientsSides[1].push(copyIngredientsSides[0][index]);
    copyIngredientsSides[1].sort((a, b) => sortByName(a, b, "nome"));
    copyIngredientsSides[0].splice(index, 1);
    setIngredientsSides(copyIngredientsSides);
    inputSearchIngredientsHandler(inputSearchIngredients);
  };

  const clearNutrientsSearch = () => {
    setNutrientsSearch(nutrientsSides[0]);
    setInputSearchNutrients("");
  };

  const removeNutrient = (index, e) => {
    if (e.target.className !== "form-control form-control-sm") {
      const copyNutrientsSides = [...nutrientsSides];
      copyNutrientsSides[1][index].min = "";
      copyNutrientsSides[1][index].max = "";
      copyNutrientsSides[0].push(copyNutrientsSides[1][index]);
      copyNutrientsSides[0].sort((a, b) => sortByName(a, b, "name"));
      copyNutrientsSides[1].splice(index, 1);
      setNutrientsSides(copyNutrientsSides);
      inputSearchNutrientsHandler(inputSearchNutrients);
    }
  };

  const selectNutrient = nutrientName => {
    const index = nutrientsSides[0]
      .map(nutrient => nutrient.name)
      .indexOf(nutrientName);
    const copyNutrientsSides = [...nutrientsSides];
    copyNutrientsSides[0][index].min = "";
    copyNutrientsSides[0][index].max = "";
    copyNutrientsSides[1].push(copyNutrientsSides[0][index]);
    copyNutrientsSides[1].sort((a, b) => sortByName(a, b, "name"));
    copyNutrientsSides[0].splice(index, 1);
    setNutrientsSides(copyNutrientsSides);
    inputSearchNutrientsHandler(inputSearchNutrients);
  };
  const tabs = [
    {
      label: "Ingredientes",
      component: (
        <Ingredients
          inputSearchIngredients={inputSearchIngredients}
          clearIngredientsSearch={clearIngredientsSearch}
          removeIngredient={removeIngredient}
          selectIngredient={selectIngredient}
          ingredientsSearch={ingredientsSearch}
          ingredientsSides={ingredientsSides}
          inputSearchIngredientsHandler={inputSearchIngredientsHandler}
          inputSelectedIngredientItemHandler={
            inputSelectedIngredientItemHandler
          }
        />
      )
    },
    {
      label: "Nutrientes",
      component: (
        <Nutrients
          inputSearchNutrients={inputSearchNutrients}
          clearNutrientsSearch={clearNutrientsSearch}
          removeNutrient={removeNutrient}
          selectNutrient={selectNutrient}
          nutrientsSearch={nutrientsSearch}
          nutrientsSides={nutrientsSides}
          inputSearchNutrientsHandler={inputSearchNutrientsHandler}
          inputSelectedNutrientItemHandler={inputSelectedNutrientItemHandler}
        />
      )
    },
    {
      label: "Fórmulas",
      component: <Results tableIngredients={tableIngredients} />,
      onlyIf: "formulado"
    }
  ];

  const formulate = nutrientsSides => {
    const constNutrients = {};
    nutrientsSides.forEach(nutrient => {
      constNutrients[nutrient.id] = {
        min: nutrient.min ? +nutrient.min * ingredientsSides[1].length : 0
      };
      if (nutrient.max) {
        constNutrients[nutrient.id].max =
          +nutrient.max * ingredientsSides[1].length;
      }
    });

    const constraints = {
      ...{
        ...ingredientsSides[1].map(item => ({
          min: item.min ? (+item.min / 100) * ingredientsSides[1].length : 0,
          max:
            (+item.max / 100) * ingredientsSides[1].length ||
            ingredientsSides[1].length
        }))
      },
      ...constNutrients,
      perc: { equal: ingredientsSides[1].length }
    };
    const model = {
      optimize: "price",
      opType: "min",
      constraints,
      variables: {
        ...{
          ...ingredientsSides[1].map((ingredient, index) => ({
            ...ingredient,
            perc: 1,
            [index]: 1
          }))
        }
      }
    };
    return { nutrients: [...nutrientsSides], solver: solver.Solve(model) };
  };

  useEffect(() => {
    const loadIngredients = async () => {
      setGetIngredients(false);
      const response = await api.get("fishingredient").catch(err => err);
      if (!response.data) {
        if (response.message === "Request failed with status code 401") {
          return logout();
        }
      }
      const copyIngredients = response.data.docs
        .sort((a, b) => sortByName(a, b, "name"))
        .map(item => {
          item.max = "";
          item.min = "";
          return item;
        });
      setIngredientsSides([copyIngredients, []]);
      setIngredientsSearch(copyIngredients);
    };
    if (getIngredients) {
      loadIngredients();
    }
  }, [props, getIngredients]);

  return (
    <form
      className="formulador"
      onSubmit={async event => {
        event.preventDefault();
        const feedResults = [];
        const nomeDBConvert = {
          lysP: { param: "lys", name:"Lys", value: 1.05 },
          metCysP: { param: "metCys", name: "Met + Cis", value: 1.05 },
          thrP: { param: "thr", name: "Thr", value: 1.05 },
          trpP: { param: "trp", name: "Trp", value: 2 },
          valP: { param: "val", name: "Val", value: 1.5 },
          argP: { param: "arg" , name: "Arg"},
          ileP: { param: "ile", name: "Ile" },
          leuP: { param: "leu", name: "Leu" },
          pheP: { param: "phe", name: "Phe" },
          hisP: { param: "his", name: "His" }
        };
        props.result.forEach(result => {
          const copyNutrientsSides = [...nutrientsSides[1]];
          Object.keys(result).forEach(key => {
            if (nomeDBConvert[key]) {
              const nutrient = { 
                name: nomeDBConvert[key].name,            
                id: nomeDBConvert[key].param,
                min: +result[key]
              };
              if (nomeDBConvert[key].value) {
                nutrient.max = +result[key] * nomeDBConvert[key].value;
              }
              copyNutrientsSides.push(nutrient);
            }
          });
          feedResults.push(formulate(copyNutrientsSides));
        });        
        const results = feedResults.map((feed, index) => {
          const {
            bounded,
            feasible,
            result,
            ...ingredietsResults
          } = feed.solver;
          return {
            fase: `Fórmula de ração para faixa de peso de ${
              props.result[index].start
            } a ${props.result[index].end} g`,
            ingredients: Object.keys(ingredietsResults).map(key => ({
              name: ingredientsSides[1][+key].name,
              percentage:
                (ingredietsResults[key] / ingredientsSides[1].length) * 100,
              price:
                ingredientsSides[1][+key].price *
                (ingredietsResults[key] / ingredientsSides[1].length)
            })),
            nutrients: feed.nutrients.map(nutrient => {
              let sum = 0;
              Object.keys(ingredietsResults).forEach(key => {
                sum +=
                  ingredientsSides[1][+key][nutrient.id] *
                  (ingredietsResults[key] > 0
                    ? ((ingredietsResults[key] / ingredientsSides[1].length) *
                        100) /
                      100
                    : 0);
              });
              return { ...nutrient, value: sum };
            })
          };
        });
        console.log(results);
        setTableIngredients(results);
        setStateForm("formulado");
        setTabActiveIndex(2);
      }}
    >
      <div className="row">
        <div className={`col-sm-${stateForm ? "9" : "6"} tabs-container`}>
          <ul className="nav nav-tabs formulador__tabs">
            {tabs
              .filter(tab => tab.onlyIf === stateForm || !tab.onlyIf)
              .map((tab, index) => (
                <li
                  className="nav-item"
                  key={`tab${removerAcentos(tab.label) + index}`}
                >
                  <span
                    className={`nav-link pointer ${
                      index === tabActiveIndex ? "active" : ""
                    }`}
                    onClick={() => setTabActiveIndex(index)}
                  >
                    {tab.label}
                  </span>
                </li>
              ))}
          </ul>
        </div>
        <div className={`formulador__submit col-sm-${stateForm ? "3" : "6"}`}>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!ingredientsSides[1][0]}
          >
            Formular Ração
          </button>
        </div>
      </div>
      {tabs[tabActiveIndex].component}
    </form>
  );
};

export default Formulador;

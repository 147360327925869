import React, { useState } from "react";
import Checkbox from "../Checkbox";
import api from "../../services/api";
import ReactLoading from "react-loading";

const ListFilter = props => {
  const [show, setShow] = useState(false);

  const [items, setItems] = useState([]);

  const [itemsFiltered, setItemsFiltered] = useState([]);

  const [inputSearch, setInputSearch] = useState("");

  const [loading, setLoading] = useState(true);

  const showHandler = async () => {
    const copyShow = show;
    setShow(!show);
    if (!copyShow) {
      setLoading(true);
      const response = await api.get("/filter", {
        params: {
          query: props.match.params.query,
          param: props.param,
          model: props.model
        }
      });
      setItems(response.data);
      setItemsFiltered(response.data);
      setLoading(false);
    } else {
      setItems([]);
    }
  };

  const checkboxHandler = (checked, index) => {
    const copyItems = [...itemsFiltered];
    copyItems[index].checked = checked;
    setItemsFiltered(copyItems);
  };

  const clearString = value =>
    value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

  const inputSearchHandler = e => {
    setInputSearch(e.target.value);
    const value = clearString(e.target.value);
    const copyItems = [...items].filter(item => {
      return clearString(item[props.param]).indexOf(value) > -1;
    });
    setItemsFiltered(copyItems);
  };

  const clearInput = () => {
    setItemsFiltered([...items]);
    setInputSearch("");
  };
  const filterHandler = () => {
    const query = JSON.parse(props.match.params.query);
    if (!query._id) {
      query._id = { $in: [] };
    }
    const filter = items.filter(item => !!item.checked).map(item => item.ids);

    filter.forEach(item => {
      item.forEach(id => {
        if (query._id.$in.indexOf(id) === -1) {
          query._id.$in.push(id);
        }
      });
    });
    props.pushRoute(
      props.match.path
        .replace(":page", 1)
        .replace(":limit", props.match.params.limit)
        .replace(":sort", props.match.params.sort)
        .replace(":query", encodeURI(JSON.stringify(query)))
    );
    setShow(false);
  };

  const sortHandler = value => {
    props.pushRoute(
      props.match.path
        .replace(":page", 1)
        .replace(":limit", props.match.params.limit)
        .replace(":sort", value + props.param)
        .replace(":query", props.match.params.query)
    );
    setShow(false);
  };

  return (
    <th className={`filter ${show ? "open" : ""}`}>
      <div onClick={showHandler}>
        {props.label} <i className="fa fa-filter"></i>{" "}
        {
          props.match.params.sort.replace('-', '') === props.param ? (
            props.match.params.sort.indexOf('-') > -1 ? 
            <i className="fa fa-sort-alpha-desc"></i> : <i className="fa fa-sort-alpha-asc"></i>
          ) :<i className="fa fa-sort"></i>
        }
      </div>
      <ul className="dropdown-menu filter__menu animated fadeInRight m-t-xs">
        <li>
          <div className="filter__menu--sort">
            <button
              className="btn btn-default btn-block btn-sm"
              onClick={() => sortHandler("")}
            >
              Ordenar crescente <i className="fa fa-sort-alpha-asc" />
            </button>
          </div>
        </li>
        <li>
          <div className="filter__menu--sort">
            <button
              className="btn btn-default btn-block btn-sm"
              onClick={() => sortHandler("-")}
            >
              Ordenar decrescente <i className="fa fa-sort-alpha-desc" />
            </button>
          </div>
        </li>
        <li>
          <div className="filter__menu--input">
            <input
              className="form-control"
              placeholder={`Buscar ${props.label}`}
              value={inputSearch}
              onChange={inputSearchHandler}
            />
            {inputSearch ? (
              <i className="fa fa-trash" onClick={clearInput}></i>
            ) : null}
          </div>
        </li>
        <li>
          <div className="filter_menu--items">
            {loading ? (
              <div className="filter__menu--loading">
                <ReactLoading
                  type="spin"
                  color="#286090"
                  height={30}
                  width={30}
                />
              </div>
            ) : (
              itemsFiltered.map((item, index) => (
                <div
                  className="filter__menu--checkbox"
                  key={item[props.param] + index}
                >
                  <Checkbox
                    label={item[props.param]}
                    checked={item.checked}
                    onChange={checked => checkboxHandler(checked, index)}
                  />
                </div>
              ))
            )}
          </div>
        </li>
        <li>
          <div className="filter__menu--buttons">
            <button className="btn btn-primary btn-sm" onClick={filterHandler}>
              Filtrar
            </button>
            <button className="btn btn-warning btn-sm" onClick={showHandler}>
              Fechar
            </button>
          </div>
        </li>
      </ul>
    </th>
  );
};

export default ListFilter;

import React from "react";
import utils from "../../../../../utils/functions";

const Results = props => {  
  return (
    <div className="resultados mt-3 ">
      <table className="table table-resultados">
        <thead className="thead-dark table-resultados__header">
          <tr>
            <th className="col">Ingrediente</th>
            <th className="col">Porcentagem</th>
            <th className="col">Preço/kg</th>
          </tr>
        </thead>
        <tbody>
          {props.tableIngredients
            .sort((a, b) => {
              if (a.percentage > b.percentage) {
                return -1;
              }
              if (a.percentage < b.percentage) {
                return 1;
              }
              return 0;
            })
            .map(ingredient => (
              <tr key={ingredient.name}>
                <td>{ingredient.name}</td>
                <td>{utils.filterMask("number", ingredient.percentage, 2)}%</td>
                <td>{utils.filterMask("number", ingredient.price, 2)} </td>
              </tr>
            ))}
          <tr>
            <td></td>
            <td>
              {props.tableIngredients[1]
                ? utils.filterMask(
                    "number",
                    props.tableIngredients
                      .map(ingredient => ingredient.percentage)
                      .reduce((p, n) => {
                        return p + n;
                      }),
                    2
                  )
                : props.tableIngredients[0].percentage}
              %
            </td>
            <td>
              {props.tableIngredients[1]
                ? utils.filterMask(
                    "number",
                    props.tableIngredients
                      .map(ingredient => ingredient.price)
                      .reduce((p, n) => p + n),
                    2
                  )
                : props.tableIngredients[0].price}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Results;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import logo from "../../assets/img/lavinesp.png";
import api from "../../services/api";
import { login } from "../../services/auth";
import "./index.css";

const Login = props => {
  const erroMessageInitialState = {
    message: "",
    username: false,
    password: false
  };

  const [form, setForm] = useState({
    username: "",
    password: ""
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(erroMessageInitialState);

  const inputFormHandler = (value, key) => {
    const copyForm = { ...form };
    copyForm[key] = value;
    setForm(copyForm);
  };

  const submitData = async e => {
    e.preventDefault();
    if (!form.password || !form.username) {
      setTimeout(() => {
        setErrorMessage(erroMessageInitialState);
      }, 4000);
      return setErrorMessage({
        message: "Preencha os campos Obrigatórios!",
        password: !form.password,
        username: !form.username
      });
    }
    setLoading(true);
    const response = await api.post("/auth/signin", form).catch(err => err);
    setLoading(false);
    if (!response.data) {
      return Swal.fire("Login", "Usuário ou Senha Incorreto!", "error");
    }
    login(response.data.token);
    return props.history.push("/");
  };

  const year = new Date().getFullYear();
  return (
    <div className="login">
      <div className="loginColumns animated fadeInDown">
        <hr />
        <div className="row">
          <div
            className="col-md-6 text-justify"
            style={{ textAlignLast: "center" }}
          >
            <h3 className="font-bold">
              POULTRY MODEL
              <br />
              <small>
                <b>A web portal for poultry nutritionists focus on modeling</b>
                <br />
                Making poultry models available to everyone
              </small>
            </h3>

            <p>
              Poultry model is a web portal with tools to help poultry
              nutritionists making decisions.
            </p>
            <p>
              The web portal granted access to the simulation models - Broiler
              Growth Model and - Egg Production Model, where users are able to
              simulate distinct environment and nutrition, before applying
              changes on poultry farm, saving time and increasing the economic
              return. Furthermore, three tools are also available to elaborate
              nutritional programs for broiler chickens, pullets, and laying
              hens.
            </p>
            <p>
              To have access to Poultry Model portal, you should be registered.
              Please send email to one of the address below:
              <br />- Nilva Sakomura – nilvasakomura@gmail.com -
              <br />- Matheus Reis – matheusdpreis@gmail.com -
              <br />- Rony Riveros – ronriveros@gmail.com -
            </p>
          </div>
          <div className="col-md-6 text-center">
            <div className="login__logo2">
              <img className="img-responsive" src={logo} alt="logo" />
            </div>
            <form className="m-t" onSubmit={submitData}>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-mail"
                  required=""
                  value={form.username}
                  onChange={e => inputFormHandler(e.target.value, "username")}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required=""
                  value={form.password}
                  onChange={e => inputFormHandler(e.target.value, "password")}
                />
              </div>
              {errorMessage.message ? (
                <div className="alert alert-danger">{errorMessage.message}</div>
              ) : null}
              <button
                type="submit"
                className="btn btn-primary block full-width m-b"
                disabled={loading}
              >
                {loading ? (
                  <div className="change-pass__spin">
                    <ReactLoading
                      type="spin"
                      color="#fff"
                      height={19}
                      width={19}
                    />
                  </div>
                ) : (
                  "Login"
                )}
              </button>

              <Link to="/forgot">
                <small>Forgot Password?</small>
              </Link>
              <p style={{ marginTop: "10px" }}>
                <small>
                  A trial period of 15 days is applied. To get a license for
                  using the models, please contact one of the emails below:{" "}
                  <br />
                  - Matheus Reis – matheusdpreis@gmail.com - <br />- Rony
                  Riveros – ronriveros@gmail.com -
                </small>
              </p>
            </form>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12 text-center">
            {" "}
            National Institute of Industrial Propriety <small>© {year}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { Fragment, useState } from "react";
import "./index.css";
import FormInputs from "./FormInputs";
import Result from "./Result";
import Formulador from "./Formulador";
import HeaderContent from "../../components/HeaderContent";
import Ingredients from "./Ingredients/List";
import NewIngredient from "./Ingredients/CreateEdit";

const Fish = () => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  const initialInput = {
    feedProgram: {
      start: {
        value: "",
        label: "Início"
      },
      end: {
        value: "",
        label: "Fim"
      },
      temperature: {
        value: "",
        label: "Temperatura"
      }
    }
  };

  const [inputs, setInputs] = useState({
    feedProgram: [
      {
        start: { value: (0.02 * 1000).toFixed(0) },
        end: { value: (0.1 * 1000).toFixed(0) },
        temperature: { value: 28 }
      },
      {
        start: { value: (0.101 * 1000).toFixed(0) },
        end: { value: (0.3 * 1000).toFixed(0) },
        temperature: { value: 28 }
      },
      {
        start: { value: (0.301 * 1000).toFixed(0) },
        end: { value: (0.5 * 1000).toFixed(0) },
        temperature: { value: 28 }
      },
      {
        start: { value: (0.501 * 1000).toFixed(0) },
        end: { value: (0.6 * 1000).toFixed(0) },
        temperature: { value: 28 }
      },
      {
        start: { value: (0.601 * 1000).toFixed(0) },
        end: { value: (1 * 1000).toFixed(0) },
        temperature: { value: 28 }
      }
    ]
  });

  const [result, setResult] = useState([]);

  const [ingredientId, setIngredientId] = useState("");

  const [getIngredient, setGetIngredient] = useState(false);

  const [ingredients, setIngredients] = useState([]);

  const [getIngredients, setGetIngredients] = useState(true);

  const addLineFeedProgram = () => {
    const copyInputs = { ...inputs };
    copyInputs.feedProgram.push({ ...initialInput.feedProgram });
    if (copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value) {
      copyInputs.feedProgram[copyInputs.feedProgram.length - 1].start.value =
        +copyInputs.feedProgram[copyInputs.feedProgram.length - 2].end.value +
        1;
    }
    setInputs(copyInputs);
  };

  const removeLine = key => {
    const copyInputs = { ...inputs };
    copyInputs[key].pop();
    setInputs(copyInputs);
  };

  const inputHandler = (index, key, event, keyList) => {
    const copyInputs = { ...inputs };
    if (index >= 0) {
      let value = event.target.value;
      copyInputs[keyList] = [...inputs[keyList]];
      copyInputs[keyList][index][key].value = value === '' ? '' : +value;
      if(keyList === 'feedProgram' && key === 'end' && index < (inputs[keyList].length - 1)){
        copyInputs[keyList][index + 1].start.value = +value + 1;
      }
    } else {
      copyInputs[key] = event.target.value;
    }    
    setInputs(copyInputs);
  };

  const getResponseReport = () => {
    const response = {
      days: [],
      output: [],
      lastDayOfWeek: []
    };
    const params = {
      bodyWeightWm: 1.466,
      bodyWeightB: 0.00899,
      bodyWeightWi: 0.02,
      bodyProteinWm: 343.7,
      bodyProteinB: 0.00708,
      bodyProteinWi: 7.7121,
      meJuv: 3036,
      mePre: 3075,
      lysJun: 1.94,
      lysPre: 1.46,
      lysAdu: 1.36,
      lys: 1.64,
      pv: [
        { limit: 0.01, value: 0.1 },
        { limit: 0.0201, value: 0.08 },
        { limit: 0.0401, value: 0.06 },
        { limit: 0.1301, value: 0.05 },
        { limit: 0.2501, value: 0.04 },
        { limit: 0.3001, value: 0.035 },
        { limit: 0.4801, value: 0.03 },
        { limit: 0.6001, value: 0.025 },
        { limit: 0.7001, value: 0.02 },
        { limit: 0.8501, value: 0.015 },
        { limit: 1.501, value: 0.01 }
      ],
      idealRelationshipLimit: [0.06, 0.2],
      idealRelationship: {
        met: [0.41305, 0.4112, 0.4149],
        metCys: [0.64, 0.64, 0.64],
        thr: [0.93, 1.0342, 0.8896],
        trp: [0.24, 0.1549, 0.2307],
        arg: [1.25, 0.8579, 0.8073],
        val: [0.76, 0.6025, 0.7263],
        ile: [0.57, 0.5605, 0.5124],
        leu: [0.96, 0.8364, 0.6601],
        phe: [0.6671, 0.6369, 0.6973],
        his: [0.34, 0.2979, 0.34]
      },
      tempXfi: [
        { temp: 12, fi: 0.0 },
        { temp: 14, fi: 0.0 },
        { temp: 16, fi: 0.0 },
        { temp: 18, fi: 0.0 },
        { temp: 20, fi: 0.11 },
        { temp: 22, fi: 0.28 },
        { temp: 24, fi: 0.45 },
        { temp: 26, fi: 0.63 },
        { temp: 28, fi: 0.81 },
        { temp: 29, fi: 0.91 },
        { temp: 30, fi: 1 },
        { temp: 31, fi: 1 }
      ],
      tempObs: [
        { day: 0, value: 26.7 },
        { day: 31, value: 28.56 },
        { day: 71, value: 27.81 },
        { day: 99, value: 26.53 },
        { day: 127, value: 26.38 },
        { day: 155, value: 27.13 },
        { day: 183, value: 27.52 },
        { day: 211, value: 27.51 },
        { day: 239, value: 27.31 },
        { day: 267, value: 27.26 },
        { day: 293, value: 27.78 }
      ]
    };
    let indexDay = 0;
    let bodyWeight = 0;
    let indexTemp = 0;
    let indexIdeal = 0;
    let indexPv = 0;
    inputs.feedProgram.forEach(item => {
      const end = item.end.value / 1000;
      const output = {
        dfi: 0,
        fi: 0,
        lysP: 0,
        metCysP: 0,
        thrP: 0,
        trpP: 0,
        argP: 0,
        valP: 0,
        ileP: 0,
        leuP: 0,
        pheP: 0,
        hisP: 0,
        de: 0
      };
      let indexOutput = 0;
      while (bodyWeight <= end) {
        const newIndexTemp = params.tempObs
          .map(item => item.day)
          .indexOf(indexDay);
        if (newIndexTemp > -1) {
          indexTemp = newIndexTemp;
        }
        const dayData = {
          day: indexDay,
          tempObs: params.tempObs[indexTemp].value,
          temperature: item.temperature.value
        };
        //const indexFi = params.tempXfi.map((item)=>item.temp).indexOf(dayData.temperature)
        //const fi = indexFi > -1 ? params.tempXfi[indexFi].fi : 0;
        dayData.bw =
          params.bodyWeightWm *
          Math.exp(
            -Math.exp(
              Math.log(-Math.log(params.bodyWeightWi / params.bodyWeightWm)) -
                params.bodyWeightB * dayData.day
            )
          );
        if (dayData.bw > end) break;
        if (
          params.idealRelationshipLimit[indexIdeal] &&
          dayData.bw > params.idealRelationshipLimit[indexIdeal]
        ) {
          indexIdeal++;
        }
        dayData.tgc = !indexDay
          ? 0
          : ((Math.pow(dayData.bw, 0.3333) -
              Math.pow(response.days[indexDay - 1].bw, 0.3333)) /
              dayData.tempObs) *
            100;
        dayData.bwTgc = !indexDay
          ? dayData.bw
          : Math.pow(
              Math.pow(response.days[indexDay - 1].bwTgc, 0.3333) +
                (dayData.tgc / 100) * dayData.temperature,
              1 / 0.3333
            );
        if (params.pv[indexPv] && dayData.bw > params.pv[indexPv].limit) {
          indexPv++;
        }
        dayData.bwg = !indexDay
          ? 0
          : dayData.bwTgc - response.days[indexDay - 1].bwTgc;
        dayData.bpw = Math.pow(2.71828, 5.2437 + Math.log(dayData.bw) * 0.8691);
        dayData.bpg = !indexDay
          ? 0
          : dayData.bpw - response.days[indexDay - 1].bpw;
        if (indexDay) {
          dayData.re = 6.128 * (dayData.bwg * 1000) * 0.238846;
          dayData.hee =
            (-30.331 + 2.394 * dayData.temperature) *
            Math.pow(dayData.bwTgc, 0.8) *
            0.238846;
          dayData.hie = 0.45 * (dayData.re + dayData.hee);
          dayData.euze = 0.057 * (dayData.re + dayData.hee + dayData.hie);
          dayData.de = dayData.re + dayData.hee + dayData.hie + dayData.euze;
          dayData.lys =
            38.28 * Math.pow(dayData.bwTgc, 0.8) +
            (-5.0329 + 12.505 * (dayData.bwg * 1000)) / 0.66;
          dayData.metCys =
            dayData.lys * params.idealRelationship.metCys[indexIdeal];
          dayData.thr = dayData.lys * params.idealRelationship.thr[indexIdeal];
          dayData.trp = dayData.lys * params.idealRelationship.trp[indexIdeal];
          dayData.arg = dayData.lys * params.idealRelationship.arg[indexIdeal];
          dayData.val = dayData.lys * params.idealRelationship.val[indexIdeal];
          dayData.ile = dayData.lys * params.idealRelationship.ile[indexIdeal];
          dayData.leu = dayData.lys * params.idealRelationship.leu[indexIdeal];
          dayData.phe = dayData.lys * params.idealRelationship.phe[indexIdeal];
          dayData.his = dayData.lys * params.idealRelationship.his[indexIdeal];
          dayData.dfi =
            (dayData.lys /
              1000 /
              ((dayData.bwTgc < 0.05
                ? params.lysJun
                : dayData.bwTgc < 0.43
                ? params.lysPre
                : dayData.bwTgc < 0.73
                ? params.lysAdu
                : params.lys) /
                100)) *
            ((0.0037 * Math.pow(dayData.temperature, 2) +
              0.4018 * dayData.temperature -
              8.8119) /
              6.5721);
          /* 
            ((0.0037 * (dayData.temperature ^ 2) +
              0.4018 * dayData.temperature -
              8.8119) /
              (0.0037 * (30 ^ 2) + 0.4018 * 30 - 8.8119)) */

          // ((dayData.temperature < 27.673
          //   ? 1.0779 - 0.072 * (27.673 - dayData.temperature)
          //   : 1.0779 - 0.33 * (dayData.temperature - 27.673)) < 0
          //   ? 0
          //   : dayData.temperature < 27.673
          //   ? 1.0779 - 0.072 * (27.673 - dayData.temperature)
          //   : 1.0779 - 0.33 * (dayData.temperature - 27.673));
          dayData.fi = (dayData.dfi / dayData.bwTgc / 1000) * 100;
          dayData.lysP = dayData.lys / dayData.dfi / 10;
          dayData.metCysP = dayData.metCys / dayData.dfi / 10;
          dayData.thrP = dayData.thr / dayData.dfi / 10;
          dayData.trpP = dayData.trp / dayData.dfi / 10;
          dayData.argP = dayData.arg / dayData.dfi / 10;
          dayData.valP = dayData.val / dayData.dfi / 10;
          dayData.ileP = dayData.ile / dayData.dfi / 10;
          dayData.leuP = dayData.leu / dayData.dfi / 10;
          dayData.pheP = dayData.phe / dayData.dfi / 10;
          dayData.hisP = dayData.his / dayData.dfi / 10;
          dayData.pv = params.pv[indexPv].value;
          dayData.cr = dayData.bwTgc * dayData.pv;
          Object.keys(output).forEach(key => {
            output[key] += dayData[key];
          });
          indexOutput++;
        }
        response.days.push({ ...dayData });
        bodyWeight = dayData.bw;
        indexDay++;
      }
      Object.keys(output).forEach(key => {
        output[key] = output[key] / indexOutput;
      });
      response.output.push({ ...output });
      indexOutput = 0;
    });
    return response;
  };

  const generateReport = () => {
    const response = getResponseReport();
    setResult(
      response.output.map((item, index) => {
        item.start = inputs.feedProgram[index].start.value;
        item.end = inputs.feedProgram[index].end.value;
        return item;
      })
    );
    setTabActiveIndex(1);
  };

  const editIngredient = id => {
    setIngredientId(id);
    setGetIngredient(true);
    setTabActiveIndex(4);
  };

  const tabs = [
    {
      label: "Início",
      component: (
        <FormInputs
          inputs={inputs}
          addLineFeedProgram={addLineFeedProgram}
          removeLine={removeLine}
          inputHandler={inputHandler}
          generateReport={generateReport}
        />
      )
    },
    {
      label: "Resultado",
      component: <Result result={result} />
    },
    {
      label: "Formulador",
      component: <Formulador result={result} />
    },
    {
      label: "Lista de Ingredientes",
      component: (
        <Ingredients
          edit={editIngredient}
          setIngredients={setIngredients}
          ingredients={ingredients}
          runLoadItems={getIngredients}
          setRunLoadItems={setGetIngredients}
        />
      )
    },
    {
      label: "Criar/Editar Ingrediente",
      component: (
        <NewIngredient
          id={ingredientId}
          setId={setIngredientId}
          hasGet={getIngredient}
          setGet={setGetIngredient}
          ingredients={ingredients}
          setGetIngredients={setGetIngredients}
          setTab={setTabActiveIndex}
        />
      )
    }
  ];

  return (
    <Fragment>
      <HeaderContent
        label="Programa Nutricional para Tilápias"
        links={[
          { label: "home", link: "/home" },
          { label: "Programa Nutricional para Tilápias", active: true }
        ]}
      />
      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-md-12 text-left">
            <div className="ibox float-e-margins">
              <div className="ibox-content icons-box">
                <div className="row">
                  <div className="col-md-12 tabs-container">
                    <ul className="nav nav-tabs">
                      {tabs.map((tab, index) => (
                        <li className="nav-item" key={`tab${index}`}>
                          <span
                            className={`nav-link pointer ${
                              index === tabActiveIndex ? "active" : ""
                            }`}
                            onClick={() => setTabActiveIndex(index)}
                          >
                            {tab.label}
                          </span>
                        </li>
                      ))}
                    </ul>
                    {tabs.map((tab, index) => (
                      <div
                        key={`tab${index}`}
                        style={{
                          display: index === tabActiveIndex ? "block" : "none"
                        }}
                      >
                        {tab.component}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Fish;

import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "../../../../components/Loading";
import api from "../../../../services/api";
import DropdownContext from "../../../../contexts/dropdownContext";
import { logout } from "../../../../services/auth";
import utils from "../../../../utils/functions.js";
import TextField from "@material-ui/core/TextField";

const ListIngredient = props => {
  const dropdownContext = useContext(DropdownContext);  

  const [totalIngredients, setTotalIngredients] = useState(0);

  const [loading, setLoading] = useState(true);

  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const loadItems = async () => {
      props.setRunLoadItems(false);
      const response = await api.get("fishingredient").catch(err => err);
      if (!response.data) {
        if (response.message === "Request failed with status code 401") {
          return logout();
        }
      }
      props.setIngredients(response.data.docs);
      setTotalIngredients(response.data.total);
      setLoading(false);
    };
    if (props.runLoadItems) {
      loadItems();
    }
  }, [props.runLoadItems, props]);
  return (
    <div className="wrapper wrapper-content animated fadeInRight">
      {loading ? (
        <Loading type="spin" color="#0451e8" height={30} width={30} />
      ) : (
        <div className="row">
          <div className="col-md-12 text-left">
            <div className="ibox float-e-margins">
              <div
                className={`ibox-title opcoes ${
                  dropdownContext.showDropdown.options ? "open" : ""
                }`}
                style={{ borderTop: "none" }}
              >
                <small>
                  {totalIngredients} Ingrediente
                  {totalIngredients > 1 ? "s" : ""} cadastrado
                  {totalIngredients > 1 ? "s" : ""}&nbsp;
                </small>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => props.edit("")}
                  >
                    <i className="fa fa-flask" /> Novo Ingrediente
                  </button>
                </div>
              </div>
              <div className="ibox-content icons-box">
                <div className="row">
                  <div className="col-md-12 text-left">
                    <div
                      className="form-group"
                      style={{ position: "relative" }}
                    >
                      <TextField
                        type="text"
                        className="form-control"
                        label="Digite o nome do ingrediente"
                        value={searchInput}
                        onChange={event => setSearchInput(event.target.value)}
                      />
                      {searchInput ? (
                        <i
                          style={{
                            position: "absolute",
                            top: "22px",
                            right: "0"
                          }}
                          className="fa fa-trash pointer"
                          onClick={() => setSearchInput("")}
                        />
                      ) : (
                        <i
                          className="fa fa-search"
                          style={{
                            position: "absolute",
                            top: "22px",
                            right: "0"
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-left">
                    <div className="text-left">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Index</th>
                            <th>Nome</th>
                            <th>Preço</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.ingredients
                            .filter(
                              ingredient =>
                                ingredient.name
                                  .toUpperCase()
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                                  .indexOf(
                                    searchInput
                                      .toUpperCase()
                                      .normalize("NFD")
                                      .replace(/[\u0300-\u036f]/g, "")
                                  ) > -1
                            )
                            .map((ingredient, index) => (
                              <tr key={ingredient._id}>
                                <td>{index + 1}</td>
                                <td>{ingredient.name}</td>
                                <td>
                                  R${" "}
                                  {utils.filterMask(
                                    "number",
                                    ingredient.price,
                                    2
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => props.edit(ingredient._id)}
                                    type="button"
                                  >
                                    {ingredient.user ? (
                                      <i className="fa fa-edit"></i>
                                    ) : (
                                      <i className="fa fa-eye"></i>
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(ListIngredient);

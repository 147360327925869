import React from "react";
import "./index.css";
import { logout } from "../../../services/auth";

const TopNavBar = () => {
  return (
    <div className="row border-bottom">
      <nav className="navbar navbar-static-top">
        <div className="navbar-header"></div>
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <div className="logout" onClick={logout}>
              <i className="fa fa-sign-out"></i> Sair
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TopNavBar;

import React from "react";
import utils from "../../../../../utils/functions";

const Results = props => {  
  return (
    <div className="resultados mt-3 ">
      <table className="table table-resultados">
        <thead className="thead-dark table-resultados__header">
          <tr>
            <th className="col">Nutriente</th>
            <th className="col">Composição</th>            
          </tr>
        </thead>
        <tbody>
          {props.tableNutrients
            .sort((a, b) => {
              if (a.value > b.value) {
                return -1;
              }
              if (a.value < b.value) {
                return 1;
              }
              return 0;
            })
            .map(ingredient => (
              <tr key={ingredient.name}>
                <td>{ingredient.name}</td>
                <td>{utils.filterMask("number", ingredient.value, 2)}</td>                
              </tr>
            ))}         
        </tbody>
      </table>
    </div>
  );
};

export default Results;

import React, { useState } from "react";
import Checkbox from "../Checkbox";

const StatusFilter = props => {
  const statusFiltered = JSON.parse(props.match.params.query)[props.param];

  const [show, setShow] = useState(false);

  const [status, setStatus] = useState({
    active: !statusFiltered,
    inactive: !statusFiltered
  });

  const checkboxHandler = (checked, key) => {
    const copyStatus = { ...status };
    copyStatus[key] = checked;
    if (!copyStatus.active && !copyStatus.inactive) {
      copyStatus.active = true;
      copyStatus.inactive = true;
    }
    setStatus(copyStatus);
  };

  const filterHandler = () => {
    const query = JSON.parse(props.match.params.query);
    if (status.active && status.inactive) {
      delete query.status;
    } else {
      query.status = status.active ? true : false;
    }
    props.pushRoute(
      props.match.path
        .replace(":page", 1)
        .replace(":limit", props.match.params.limit)
        .replace(":sort", props.match.params.sort)
        .replace(":query", encodeURI(JSON.stringify(query)))
    );
    setShow(false);
  };

  const sortHandler = value => {
    props.pushRoute(
      props.match.path
        .replace(":page", 1)
        .replace(":limit", props.match.params.limit)
        .replace(":sort", value + props.param)
        .replace(":query", props.match.params.query)
    );
    setShow(false);
  };
  return (
    <th className={`filter ${show ? "open" : ""}`}>
      <div onClick={() => setShow(!show)}>
        {props.label} <i className="fa fa-filter"></i>{" "}
        {
          props.match.params.sort.replace('-', '') === props.param ? (
            props.match.params.sort.indexOf('-') > -1 ? 
            <i className="fa fa-sort-alpha-asc"></i> : <i className="fa fa-sort-alpha-desc"></i>
          ) :<i className="fa fa-sort"></i>
        }
      </div>
      <ul className="dropdown-menu filter__menu animated fadeInRight m-t-xs">
        <li>
          <div className="filter__menu--sort">
            <button
              className="btn btn-default btn-block btn-sm"
              onClick={() => sortHandler("-")}
            >
              Ordenar crescente <i className="fa fa-sort-alpha-asc" />
            </button>
          </div>
        </li>
        <li>
          <div className="filter__menu--sort">
            <button
              className="btn btn-default btn-block btn-sm"
              onClick={() => sortHandler("")}
            >
              Ordenar decrescente <i className="fa fa-sort-alpha-desc" />
            </button>
          </div>
        </li>
        <li>
          <div className="filter_menu--items">
            <div className="filter__menu--checkbox">
              <Checkbox
                label="Ativo"
                checked={status.active}
                onChange={checked => checkboxHandler(checked, "active")}
              />
            </div>
            <div className="filter__menu--checkbox">
              <Checkbox
                label="Inativo"
                checked={status.inactive}
                onChange={checked => checkboxHandler(checked, "inactive")}
              />
            </div>
          </div>
        </li>
        <li>
          <div className="filter__menu--buttons">
            <button className="btn btn-primary btn-sm" onClick={filterHandler}>
              Filtrar
            </button>
            <button
              className="btn btn-warning btn-sm"
              onClick={() => setShow(false)}
            >
              Fechar
            </button>
          </div>
        </li>
      </ul>
    </th>
  );
};

export default StatusFilter;

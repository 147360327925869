import React from "react";
import "./index.css";

const Checkbox = props => {  
  return (
    <label className="checkbox-group">
      {props.label ? (
        <span className="checkbox-group__label">{props.label}</span>
      ) : null}
      <input
        type="checkbox"
        checked={props.checked}
        onChange={e => props.onChange(e.target.checked)}
        disabled={props.disabled}
      />
      <span className="checkbox-group__checkmark"></span>
    </label>
  );
};

export default Checkbox;

import React from "react";
import "./index.css";

const Status = props => {
  return (
    <p>
      <span className={`label label-${props.status ? "info" : "danger"} status`}>
        {props.status ? "Ativo" : "Inativo"}
      </span>
    </p>
  );
};

export default Status;

import React from "react";
import ReactLoading from "react-loading";
import "./index.css";

const Loading = props => {
  return (
    <div className="row">
      <div className="col-md-12 text-left">
        <div className="ibox float-e-margins">
          <div className="ibox-content icons-box loading">
            <ReactLoading {...props} />
            <div className="loading__text">Aguarde...</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
